var main = require('integrations/main');

main.baseFiles.menu = require('./components/menu');
main.baseFiles.loginExtras = require('./components/login');
main.baseFiles.gridView = require('./components/gridView');
main.baseFiles.slider = require('./components/slider').init;
main.baseFiles.orderHistory = require('./orderHistory/orderHistory');
main.baseFiles.orderDetails = require('./orderDetails/orderDetails');
main.baseFiles.base = require('./product/base');
main.baseFiles.recommendationBundle = require('./product/recommendationBundle');
main.baseFiles.cart = require('./cart');
main.baseFiles.quickView = require('./product/quickView');
main.baseFiles.wishlist = require('./wishlist/wishlist').init;
main.baseFiles.clientSideValidation = require('./components/clientSideValidation');
main.baseFiles.sliderAccessibility = require('./components/sliderAccessibility');
main.baseFiles.footer = require('./components/footer');
main.baseFiles.toolTip = require('./components/toolTip');
main.baseFiles.account = require('./account/account');
main.baseFiles.tile = require('./product/tile');
main.baseFiles.modal = require('./components/modal').init;

var siteIntegrations = require('core/integrations/siteIntegrationsUtils');
var toggleObject = siteIntegrations.getIntegrationSettings();
if (toggleObject.bopisCartridgeEnabled) {
    // adding to main.js to make accessible for quickview
    main.baseFiles.pdpInstoreInventory = require('./integrations/bopis/product/pdpInstoreInventory');
}
if (toggleObject.GTMEnable) {
    main.baseFiles.gtm = require('./thirdParty/gtm');
}

main.baseFiles.storis = require('storis/product/storisUI');

module.exports = main;
