'use strict';

module.exports = function () {
    $(window).on('load', function () {
        $('.tns-visually-hidden').attr('aria-hidden', 'true');
        $('ul[role="tablist"] li').attr('role', 'presentation');
        $('.tab-content').attr('tabindex', '0');

        // Scroll the pre-header nav to the selected microsite on load
        var preHeaderNav = document.querySelector('.header-nav .pre-header-nav');
        if (preHeaderNav && preHeaderNav.scrollWidth > preHeaderNav.clientWidth) {
            if ($('.microsite-link.mathisoutlet').hasClass('active') || $('.microsite-link.sleepcenter').hasClass('active')) {
                preHeaderNav.scrollLeft += (preHeaderNav.scrollWidth - preHeaderNav.clientWidth);
            }
        }
    });

    $('.nav-tabs-container .nav-link, .nav-tabs .nav-link').on('keydown', function (e) {
        if (e.keyCode === 37) {
            let prevItem = $(e.target).parent().prev('.nav-item');
            prevItem.find('.nav-link').focus();
        } else if (e.keyCode === 39) {
            let nextItem = $(e.target).parent().next('.nav-item');
            nextItem.find('.nav-link').focus();
        } else if (e.keyCode === 9) {   //tab
            e.preventDefault();
            let tabContent = $(e.target).parent().parent().next('.tab-content');
            tabContent.focus();

            // handle nested tab contents
            var viewportTopPos = $(window).scrollTop();
            var navbarBottomPos = $('nav.header-nav').offset().top + $("nav.header-nav").outerHeight();;
            // if tab content top is not visible when focused, scroll its top position to viewport's middle
            if (tabContent.offset().top > viewportTopPos || (navbarBottomPos > tabContent.offset().top)) {
                $('html, body').scrollTop($(this).offset().top - ($(window).height() - $(this).outerHeight(true)) / 2);
            }
        } else if (e.keyCode === 32) {  // space
            e.preventDefault();
            $(e.target).trigger('click');
        }
    });

     // MH-17 ADA changes
     $('.nav-tabs-container .nav-link').on('keydown', function (e) {
        if (e.shiftKey && e.keyCode == 9) { // shift Tab
            if ($(e.target).hasClass('active')) {
                e.preventDefault();
                $(e.target).parents().closest('.tab-content').focus();
            }
        }
    });

    $('.tab-content').on('keydown', function (e) {
        if (e.shiftKey && e.keyCode === 9) { // shift Tab
            if ($(e.target).hasClass('tab-content')) {
                e.preventDefault();
                let shiftTab = $(e.target).prev().find('.nav-item .nav-link.active');
                shiftTab.focus();
            }
        } else if (e.keyCode === 9) { // tab
            if ($(e.target).find('.tab-pane.active .nav-item .nav-link').hasClass('active')) {
              e.preventDefault();
              $(e.target).find('.tab-pane.active .nav-item .nav-link.active').focus();
            }
        }
    });

    // MH-165 ADA -- Add 'alt' for image tag if there is no 'alt' attribute
    $('img').each(function() {
        var img = $(this);
        var imgAlt = img.attr('alt');
        if(!imgAlt && imgAlt == undefined) {
            img.attr('alt', '');
        }
    });
};
