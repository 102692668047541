'use strict';

var addOffer = require('./addOffer').addOfferToCart;
var miraklQuantityForm = require('./miraklQuantityForm').offerAvailability;

// display mirakl quickview details
function displayMiraklQuickViewDetails() {
    // replace mathis price with mirkle buybox pricing
    if ($('#quickViewModal .modal-footer .mirakle-price-details').length) {
        var buyBoxPrice = $('#quickViewModal .modal-footer .mirakle-price-details').html();
        $('.detail-panel .price-details').html(buyBoxPrice);
    }

    // move online badge and threshold after variation section
    if ($('#quickViewModal .modal-footer .badge-threshold-wrap').length) {
        var badgeThresholdContent = $('#quickViewModal .modal-footer .badge-threshold-wrap').detach();
        $('#online-threshold-container').html(badgeThresholdContent);
    }

    // move view more offers link before modal footer
    if ($('#quickViewModal .modal-footer .offer-count-msg').length) {
        var viewMoreOffersLink = $('#quickViewModal .modal-footer .offer-count-msg').detach();
        $('#view-more-offers-link').html(viewMoreOffersLink);
    } else if ($('#view-more-offers-link').length) {
        $('#view-more-offers-link').empty();
    }

    // move mirakl lead time to ship inside delivery options
    if ($('#quickViewModal .modal-footer .mirakl-leadtime-to-ship').length) {
        var leadTimeToShip = $('#quickViewModal .modal-footer .mirakl-leadtime-to-ship').detach();
        $('#mirakl-leadtimetoship-text').html(leadTimeToShip);
    }

    // Enable free shipping for mirakl under delivery option
    if ($('.prices-add-to-cart-actions .offer-add-to-cart').length) {
        var postalCode = $('.delivery-option .btn-postal-code').text().trim();
        if (postalCode !== "null") {
            $('.update-zip-code').addClass('d-none');
        } else {
            $('.update-zip-code').removeClass('d-none');
        }
        $('input[id="home"]').prop('disabled', false);
        $('.delivery-block-js').removeClass('d-none');
    } else {
        $('.update-zip-code').addClass('d-none');
        $('input[id="home"]').prop('disabled', true);
        $('.delivery-block-js').addClass('d-none');
    }
    // Update promotion tooltip
    $('body').trigger('tooltip:init');
}

/**
 * Update Buy Box with the offer information
 */
function updateBuyBox() {
    var useBuyBox = $('.miraklQuickViewBuyBox');

    if (useBuyBox.length !== 0) {
        var url = $('.miraklQuickViewBuyBox').data('url');
        $.ajax({
            url: url,
            method: 'GET',
            success: function (data) {
                $('#quickViewModal .modal-footer')
                    .empty()
                    .html(data);
                    displayMiraklQuickViewDetails();
                    updateMiraklAvailabilityMessage();
                if (data.indexOf('offer-add-to-cart') !== -1) {
                    $('.product-option').addClass('d-none');
                    $('.promotions').addClass('d-none');
                }
            }
        });
    }
}

/**
 * Updates the buybox and load offers when a variant is changed
 * @param {Object} data - The response data from the ajax call(Product-Variant)
 */
function updateVariationBuyBox(data) {
    if (data && data.isMiraklEnabled) {
        if (data.quickViewBuyboxHtml.indexOf('offer-add-to-cart') !== -1) {
            $('.promotions').addClass('d-none');
        } else {
            $('.promotions').removeClass('d-none');
        }
        if (data.callP11) {
            $('#quickViewModal .modal-footer')
            .empty()
            .html(data.quickViewBuyboxHtml);
            displayMiraklQuickViewDetails();
            updateMiraklAvailabilityMessage();
        }
    }
}

function updateMiraklAvailabilityMessage() {
    if ($('.mirakl-product-availability').length) {
        $('.mirakl-product-availability .availability-msg li').empty();
    }
    if($('.mirakl-availability-container').length) {
        var outOfStockMsg = $('.mirakl-availability-container').detach();
        $('.mirakl-product-availability .availability-msg li').html(outOfStockMsg);
    }
}

/**
 * Updates attributes after variation change + update buybox
 */
function updateAttribute() {
    $('body').on('product:afterAttributeSelect', function (e, response) {
        if (!$('.modal.show .product-quickview>.bundle-items').length && !$('.set-items').length && $('.modal.show .product-quickview').length) {
             updateVariationBuyBox(response.data);
        }
    });
}

module.exports = {
    updateBuyBox: updateBuyBox,
    miraklQuantityForm: miraklQuantityForm,
    addOffer: addOffer,
    updateAttribute: updateAttribute
};
