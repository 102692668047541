'use strict';

var base = require('core/cart/cart');
var productBase = require('client_core/product/base');

function updateCartTotals(data, isWarrantyUpdate = false) {
    var subtotal = 'everydayPriceTotal' in data.totals ? data.totals.everydayPriceTotal : data.totals.subtotalMinusWarrantyPlan;
    $('.number-of-items').empty().append(data.numItems == 1 ? data.resources.numberOfItem : data.resources.numberOfItems);
    $('.shipping-cost span').empty().append(data.totals.totalShippingCost);
    $('.tax-total span').empty().append(data.totals.totalTax);
    $('.grand-total .totals-value').empty().append(data.totals.grandTotalLessGiftCertificatePaymentInstrumentsFormatted);
    !isWarrantyUpdate && $('.sub-total span').empty().append(subtotal);
    'formattedMemberDiscountTotal' in data && $('.member-discount-total span').empty().append(data.formattedMemberDiscountTotal);   // update member discount if recalculated/provided
    $('.warranty-total span').empty().append(data.totals.warrantyPlanTotal); //Update warranty total
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    if (data.totals.membershipProductsTotal === '$0.00') {  // Update membership total
        $('.membership-total-container').hide();
    } else {
        $('.membership-total span').empty().append(data.totals.membershipProductsTotal);
    }
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
        for (var i = 0; i < data.totals.discounts.length; i++) {
        	if (data.totals.discounts[i].type == 'coupon') {
        		$('.order-discount .coupon-code').empty().append('Promo Code (' + data.totals.discounts[i].couponCode+')');
        	}
        }
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total span').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    if (data.payment.giftCertificatePaymentInstruments.length) {
        $('.giftcertificate-discount').removeClass('d-none');
        $('.giftcertificate-discount-label').text(data.totals.giftCertificatePaymentInstrumentsLabel);
        $('.giftcertificate-discount-total').text('- ' + data.totals.giftCertificatePaymentInstrumentsTotalFormatted);
    } else {
        $('.giftcertificate-discount').addClass('d-none');
    }

    if (data.totals.grandTotalLessGiftCertificatePaymentInstrumentsValue) {
        $('.cartAdditionalPaymentButtons').removeClass('d-none');
    } else {
        $('.cartAdditionalPaymentButtons').addClass('d-none');
    }

    if (data.totals.grandTotalLessGiftCertificatePaymentInstrumentsValue && data.totals.grandTotalLessGiftCertificatePaymentInstrumentsFormatted) {
        productBase.methods.updateFinancingModal(data.totals.grandTotalLessGiftCertificatePaymentInstrumentsValue, data.totals.grandTotalLessGiftCertificatePaymentInstrumentsFormatted);
        $('.checkout-btn').attr('data-price', data.totals.grandTotalLessGiftCertificatePaymentInstrumentsFormatted);
    }

    var container = $('.product-cards.product-line-items-container');
    data.items.forEach(function (item) {
        if (!item.isWarrantyProduct && $('.card .uuid-' + item.UUID).length == 0) {
            container.append(`
                <div class="card">
                    <div class="card-body product-info uuid-${item.UUID}">
                        <div class="row ">
                            <div class="col-lg-8">
                                <div class="row">
                                    <div class="col-lg-12 d-flex flex-row">
                                        <a class="item-image" href="#">
                                        ${(item.images.small && (typeof item.images.small[0].url === 'string')) ? `<img class="product-image" src="${item.images.small[0].url}" alt="${item.images.small[0].alt}" title="${item.images.small[0].title}"/>` : ''}
                                        </a>
                                        <div class="item-attributes d-flex flex-column">
                                            <div class="line-item-header">
                                                <a class="line-item-name" href="#">${item.productName}</a>
                                            </div>
                                            <p class="line-item-attributes">SKU: ${item.id}</p>
                                            <div class="line-item-price-${item.UUID}">
                                                <div class="unit-price">
                                                    <div class="price">
                                                        <span class="sales ">
                                                            <span class="value" content="${item.price.sales.value}">
                                                                <div class="pricing-everyday">
                                                                    <strong class="display-price-from">
                                                                        From
                                                                    </strong>
                                                                    <span class="display-price" tabindex="0">${item.price.sales.formatted}</span>
                                                                </div>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="line-item-promo item-${item.UUID}"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-lg-none line-item-divider">
                                <hr class="line-item-divider">
                            </div>
                            <div class="col-lg-4 product-card-footer">
                                <div class="row">
                                    <div class="col-4 col-lg-6 line-item-quantity">
                                        <p class="line-item-pricing-info">
                                            <span class="line-item-total-text qty-card-quantity-label">
                                                Quantity
                                            </span>
                                            <br>
                                            <span class="pricing qty-card-quantity-count">
                                                ${item.quantity}
                                            </span>
                                        </p>
                                    </div>
                                    <div class="col-8 col-lg-6 line-item-total-price">
                                        <label class="line-item-price-info">
                                            Total
                                        </label>
                                        <div class="item-total-${item.UUID} price">
                                            <div class="unit-price">
                                                <div class="price">
                                                    <span class="sales ">
                                                        <span class="value" content="${item.priceTotal.priceValue}">
                                                            <div class="pricing-everyday">
                                                                <strong class="display-price-from">
                                                                    From
                                                                </strong>
                                                                <span class="display-price" tabindex="0">${item.priceTotal.price}</span>
                                                            </div>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-1 hidden-md-down remove-line-item-lg"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            `);
        }

        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $('.item-' + item.UUID).empty();
        }

        // This updates the total everday and member pricing on the lineitem
        if ('priceEverydaySubtotal' in item && item.priceEverydaySubtotal.formattedPrice) {
            $('.item-total-' + item.UUID + ' .pricing-everyday .display-price').empty().append(item.priceEverydaySubtotal.formattedPrice);
        } else if (item.priceTotal.price) {
            $('.item-total-' + item.UUID + ' .pricing-everyday .display-price').empty().append(item.priceTotal.price);
        }

        // member price
        if (item.priceTotal.membership) {
            $('.item-total-' + item.UUID + ' .pricing-member .display-price').empty().append(item.priceTotal.membership);
        }

        $('.card-body.uuid-' + item.UUID + ' .qty-card-quantity-count').html("" + item.quantity);
        $('.card-body.uuid-' + item.UUID + ' select.quantity').val(item.quantity);
        $('.card-body.uuid-' + item.UUID + ' .quantity-stepper input[type="text"]').val(item.quantity);
    });

    // Remove any line items from the page that have been removed from the basket
    container.find('.card-body.product-info').each(function(index, element){
        var classes = element.className.split(/\s+/);
        for (var i=0; i<classes.length; i++) {
            if (classes[i].startsWith('uuid-')) {
                var uuid = classes[i].substring(5);
                if (!data.items.find(item => item.UUID == uuid)) {
                    $(element).closest('.card').remove();
                }
            }
        }
    });

    // Update mirakl and mathis shipping prices
    if (data.totals && data.totals.miraklShippingPrices) {
        $('.mirakl-shipping-prices .mirakl-shipping-cost').each((idx, shipment) => {
            var $shipment = $(shipment);
            var storeName = $shipment.attr('id');
            if (data.totals.miraklShippingPrices[storeName]) {
                $shipment.find('span').text(data.totals.miraklShippingPrices[storeName].price);
            } else {
                $shipment.closest('.shipping-price-info').remove();
            }
        });
    } else if ($('.mirakl-shipping-prices')) {
        $('.mirakl-shipping-prices').remove();
        $('.shipping-total .shipping-total-label').addClass('hide-icon');
        $('.leading-lines.shipping-total').css("pointerEvents", "none");
    }
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
 // Replaces rvw_autobahn_core
 function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem != null && !lineItem.isMiraklOffer) {
        $('.availability-' + lineItem.UUID).empty();

        if (lineItem.availability) {
            if (lineItem.availability.messages) {
                lineItem.availability.messages.forEach(function (message) {
                    messages += '<p class="line-item-attributes">' + message + '</p>';
                });
            }

            if (lineItem.availability.inStockDate) {
                messages += '<p class="line-item-attributes line-item-instock-date">'
                    + lineItem.availability.inStockDate
                    + '</p>';
            }
        }

        $('.availability-' + lineItem.UUID).html(messages);
    }
}

function init() {
    synchronyLoader();

    // Update cart total on load
    $(window).on('load', function () {
        var select = $('.custom-select');
        var url = select.data('action');
        var quantity = select.val();
        var productID = select.data('pid');
        var uuid = select.data('uuid');
        var listOwnerUuid = $('.wishlistItemCardsData').data() && $('.wishlistItemCardsData').data().uuid ? $('.wishlistItemCardsData').data().uuid : '';
        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid,
            listOwnerUuid: listOwnerUuid,
            updateOnLoad: true
        };

        if (url) {
            url = module.exports.appendToUrl(url, urlParams);
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    module.exports.updateCartTotals(data);
                },
                error: function (err) {
                    module.exports.createErrorNotification(err.errorMessage);
                }
            });
        }
    });

    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        module.exports.confirmDelete(actionUrl, productID, productName, uuid);
    });

    $('body').on('click', '.change-shipment-type-js', function () {
        $.spinner().start();
        $.ajax({
            url: $(this).data('href'),
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.renderedTemplate) {
                    module.exports.updateProductDetails(data, data.previousLineItemUUID);
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    module.exports.updateCartTotals(data.basket);
                    module.exports.updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    module.exports.validateBasket(data.basket);
                }

                // Make sure the shipment uuid of the change shipment link for each line item matches the actual shipment UUID
                data.basket.items.forEach(function (item) {
                    var change = $('.uuid-' + item.UUID).find('.change-shipment-type-js');
                    var href = change.data('href');
                    if (change.length && href) {
                        var parts = href.split('?');
                        parts = parts[1].split('&').reduce(
                            (object, value) => {
                                object[value.split('=')[0]] = value.split('=')[1]
                                return object;
                            },
                            {
                                base: parts[0]
                            }
                        );

                        var partsArray = [];
                        for (var part in parts) {
                            if (part == 'shipmentUUID') {
                                partsArray.push('shipmentUUID=' + item.shipmentUUID);
                            } else if (part != 'base') {
                                partsArray.push(part + '=' + parts[part]);
                            }
                        }
                        change.data('href', parts['base'] + '?' + partsArray.join('&'));
                    }
                });

                $.spinner().stop();
            },
            error: function (err) {
                module.exports.createErrorNotification(err.errorMessage);
                $.spinner().stop();
            }
        });
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        module.exports.confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = module.exports.appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $('body').trigger('cart:beforeUpdate');

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (productID === $('.add-treatment-kit-btn').data('pid')) {
                    $('#treatment-kit-status').text($('#treatment-kit-status').data('remove'));
                }
                if (data.basket.items.length === 0) {
                    $('.cart').empty().append('<div class="row"> ' +
                        '<div class="col-12 text-center"> ' +
                        '<h1>' + data.basket.resources.emptyCartMsg + '</h1> ' +
                        '</div> ' +
                        '</div>'
                    );
                    $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                    $('.number-of-items-container').removeClass('text-md-right');
                    $('.minicart-quantity').empty().append(data.basket.numItems);
                    $('.minicart-link').attr({
                        'aria-label': data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems
                    });
                    $('.minicart .popover').empty();
                    $('.minicart .popover').removeClass('show');
                    $('body').removeClass('modal-open');
                    $('html').removeClass('veiled');
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).closest('.card').remove();
                        }
                    }
                    var shopItemsWrapper = $('.uuid-' + uuid).closest('.cart-shop-item-list-wrapper');
                    $('.uuid-' + uuid).closest('.card').remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    // NOTE: there's an open issue for problems with removing products with bonus items: https://github.com/SalesforceCommerceCloud/storefront-reference-architecture/issues/748
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    module.exports.updateCartTotals(data.basket);
                    module.exports.updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    module.exports.validateBasket(data.basket);

                    // update reward header and membership summary
                    if (data.noMemberDiscount) {
                        $('.mathis-cart-rewards-header').show();
                        $('.member-discount-wrapper').removeClass('member-discount-applied');
                    } else {
                        $('.mathis-cart-rewards-header').hide();
                        $('.member-discount-wrapper').addClass('member-discount-applied');
                    }

                    //Update warranty section
                    if (data.renderedTemplate) {
                        $('#warranty-section').html(data.renderedTemplate);
                        $('#warranty-section .tooltip-icon').attr('title', data.warrantyTooltipContent);
                        $('[data-toggle="tooltip"]').tooltip();
                        $(`.associated-warranty-product-container[data-warranty-pid="${productID}"]`).addClass('d-none');
                        initToggleWarrantyProductLineItem();
                    }

                    //Update treatment kit section
                    if (data.treatmentRenderedTemplate) {
                        $('#treatment-kit-section').html($(data.treatmentRenderedTemplate).html());
                        $('.treatment-kit-content').html(data.treatmentKitContent);
                        initToggleTreatmentKit();
                    }

                    //Remove treatment kit line item and section
                    if (data.treatmentKitRemovedUUID) {
                        $('.uuid-' + data.treatmentKitRemovedUUID).parent().remove();
                    }

                    if (data.hasOwnProperty('isTreatmentKitEligible') && !data.isTreatmentKitEligible) {
                        $('#treatment-kit-section').remove();
                    }

                    if (data.newLineItemTemplate && data.treatmentKitRemovedUUID) {
                        $('.uuid-' + data.treatmentKitRemovedUUID).parent().remove();
                        $('.product-line-items-container').append(data.newLineItemTemplate);
                    }

                    if (shopItemsWrapper.length && shopItemsWrapper.find("div.card").length == 0) {
                        shopItemsWrapper.remove();
                    }

                    if (!data.applicableProtectionPlans && $('#cart-pd-slot-1').length > 0) {
                        $('#cart-pd-slot-1').addClass('d-none');
                    }
                }

                // Handle Premium Protection Program section show / hide
                var hasProtectionPlan = data.applicableProtectionPlans && data.applicableProtectionPlans.length > 0;
                var treatmentKitEligible = data.hasOwnProperty('isTreatmentKitEligible') && data.isTreatmentKitEligible;

                if(!hasProtectionPlan && !treatmentKitEligible) {
                    $('.warranty-treatment-kit-container').addClass('d-none');
                }

                $('body').trigger('cart:update', [data, uuid]);
                if (data.gtmData) {
                    if (data.basket.items.length === 0) {
                        data.gtmData.action = 'empty';
                    }
                    $('body').trigger('gtm:pushData', data.gtmData);
                }

                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('quantityStepper:change', (event, stepper) => {
        var $stepper = $(stepper);
        var isMiniCart = $stepper.closest('.minicart').length > 0;
        var selectId = $stepper.closest('.quantity-form').find('select').attr('id');
        var $select = $('.cart-page select#' + selectId);
        var value = parseInt($stepper.find('input').val());

        // if the qty change was triggered from the minicart, manually update cart qty input values
        if (isMiniCart) {
            var $cartStepper = $select.next('.quantity-stepper');
            $cartStepper.find('input').prop('value', value).prop('data-qty', value);
        }
    });

    $('body').on('change', '.quantity-form > .quantity', function() {
        var url = $(this).data('action');
        if (!url) {
            return;
        }

        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var uuid = $(this).data('uuid');
        var listOwnerUuid = $('.wishlistItemCardsData').data() && $('.wishlistItemCardsData').data().uuid ? $('.wishlistItemCardsData').data().uuid: '';
        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid,
            listOwnerUuid: listOwnerUuid
        };
        url = module.exports.appendToUrl(url, urlParams);

        $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                if ((data.error && data.error == true) || (data.isWishlist && data.isWishlist == true)) {
                    $.spinner().stop();
                    if(data.newUuid) {
                        $(this).data('uuid', data.newUuid);
                    }
                    return;
                }
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                module.exports.updateCartTotals(data);
                module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                module.exports.updateAvailability(data, uuid);
                module.exports.validateBasket(data);
                $(this).data('pre-select-qty', quantity);

                $('body').trigger('cart:update', [data, uuid]);
                $('body').trigger('gtm:qtyUpdate', uuid);

                //Update warranty section
                $('#warranty-section').html(data.renderedTemplate);
                $('#warranty-section .tooltip-icon').attr('title', data.warrantyTooltipContent);
                $('[data-toggle="tooltip"]').tooltip();
                initToggleWarrantyProductLineItem();
                if (data.gtmData) {
                    if (quantity && preSelectQty) {
                        quantity = parseInt(quantity);
                        preSelectQty = parseInt(preSelectQty);
                        if (quantity > preSelectQty) {
                            data.gtmData.action = 'add';
                        } else {
                            data.gtmData.action = 'remove';
                        }
                    }
                    $('body').trigger('gtm:pushData', data.gtmData);
                }
                $.spinner().stop();
                if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                    location.reload();
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };

        $('.totals').spinner().start();
        $('body').trigger('cart:beforeShippingMethodSelected');
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    module.exports.updateCartTotals(data);
                    module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                    module.exports.validateBasket(data);
                }
                $('body').trigger('cart:shippingMethodSelected', data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').submit(function (e) {
        e.preventDefault();
        var currentForm = $(this).closest('form');
        var formControl = currentForm.find('.form-control');

        var currentItemCount = parseInt($('.minicart-quantity').text(), 10) || 0;

        $.spinner().start();
        currentForm.find('.coupon-missing-error').hide();
        currentForm.find('.coupon-error-message').empty();
        if (!currentForm.find('.coupon-code-field').val()) {
            formControl.addClass('is-invalid');
            formControl.attr('aria-describedby', 'missingCouponCode');
            currentForm.find('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        formControl.removeClass('is-invalid');
        currentForm.find('.coupon-error-message').empty();
        $('body').trigger('promotion:beforeUpdate');

        $.ajax({
            url: currentForm.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: currentForm.serialize(),
            success: function (data) {
                if (data.error) {
                    formControl.addClass('is-invalid');
                    formControl.attr('aria-describedby', 'invalidCouponCode');
                    currentForm.find('.coupon-error-message').empty().append(data.errorMessage);
                    $('body').trigger('promotion:error', data);
                    currentForm.find('.coupon-code-field').trigger('focus');
                    $.spinner().stop();
                } else {
                    // If cart item was added/removed via promo code submit
                    if (['', null, undefined].indexOf(data.numItems) && currentItemCount !== data.numItems) {

                        // Clean Url Structure of any erroneous parameters
                        if (window.history && ['', null, undefined].indexOf(data.actionUrls.showUrl) === -1) {
                            history.replaceState({}, null, data.actionUrls.showUrl);
                        }
                        // Force uncached reload
                        window.location.reload(true);
                    } else {
                        currentForm.closest('.promocode-container').find('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                        module.exports.updateCartTotals(data);
                        module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                        module.exports.validateBasket(data);
                        $('body').trigger('promotion:success', data);
                        currentForm.find('.coupon-code-field').val('');
                        $.spinner().stop();
                    }
                }
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var currentItemCount = parseInt($('.minicart-quantity').text(), 10) || 0;
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = module.exports.appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $('body').trigger('promotion:beforeUpdate');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();

                // If cart item was added/removed via promo code removal
                if (['', null, undefined].indexOf(data.numItems) && currentItemCount !== data.numItems) {
                    // Clean Url Structure of any erroneous parameters
                    if (window.history && ['', null, undefined].indexOf(data.actionUrls.showUrl) === -1) {
                        history.replaceState({}, null, data.actionUrls.showUrl);
                    }
                    // Force uncached reload
                    window.location.reload(true);
                } else {
                    module.exports.updateCartTotals(data);
                    module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                    module.exports.validateBasket(data);
                    $.spinner().stop();
                    $('body').trigger('promotion:success', data);
                }
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                $('body').trigger('bonusproduct:edit', data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('quantity', selectedQuantity);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();
        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var form = $(this).closest('.cart-and-ipay').find('.update-cart-url').data();

        if (form) {
            form.selectedOptionValueIds = base.methods.getOptions($('#quickViewModal'));
            form.pid = module.exports.getPidValue($(this))
            $(this).parents('.card').spinner().start();
            $('body').trigger('cart:beforeUpdate');

            if (updateProductUrl) {
                $.ajax({
                    url: updateProductUrl,
                    type: 'post',
                    context: this,
                    data: form,
                    dataType: 'json',
                    success: function (data) {
                        $('#quickViewModal').modal('hide');

                        $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                        module.exports.updateCartTotals(data.cartModel);
                        module.exports.updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                        module.exports.updateAvailability(data.cartModel, form.uuid);
                        module.exports.updateProductDetails(data, form.uuid);

                        if (data.uuidToBeDeleted) {
                            $('.uuid-' + data.uuidToBeDeleted).remove();
                        }

                        module.exports.validateBasket(data.cartModel);

                        $('body').trigger('cart:update', [data, form.uuid]);

                        //Update warranty section
                        $('#warranty-section').html(data.warrantyRenderedTemplate);
                        $('#warranty-section .tooltip-icon').attr('title', data.warrantyTooltipContent);
                        $('[data-toggle="tooltip"]').tooltip();
                        initToggleWarrantyProductLineItem();

                        $.spinner().stop();
                    },
                    error: function (err) {
                        if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        } else {
                            module.exports.createErrorNotification(err.responseJSON.errorMessage);
                            $.spinner().stop();
                        }
                    }
                });
            }
        }
    });

    $('body').on('product:afterAddToCartQuickview', () => {
        var verifyCartPage = $('body').find('.page').data('action');
        if (verifyCartPage == 'Cart-Show') {
            location.reload();
        }
    });

    //responsible for filling edit gift cert modal with information from line item on cart page
    $('body').on('click', 'a.edit.editGiftCertificateLineItem', function (e) {
        e.preventDefault();

        var anchor = $(this);
        var uuid = anchor.data('uuid');
        var parentContainer = anchor.parents('.product-info.uuid-' + uuid);
        var from = parentContainer.find('.dwfrm_giftCertificate_purchase_from-' + uuid).data('value');
        var recipient = parentContainer.find('.dwfrm_giftCertificate_purchase_recipient-' + uuid).data('value');
        var recipientEmail = parentContainer.find('.dwfrm_giftCertificate_purchase_recipientEmail-' + uuid).data('value');
        var message = parentContainer.find('.dwfrm_giftCertificate_purchase_message-' + uuid).attr('title');
        var amount = parentContainer.find('.pricing.item-total-' + uuid).data('value');

        var modal = $('#editGiftCertificateLineItemModal');
        modal.find('#from').attr('value', from);
        modal.find('#recipient').attr('value', recipient);
        modal.find('#recipientEmail').attr('value', recipientEmail);
        modal.find('#confirmRecipientEmail').attr('value', recipientEmail);
        modal.find('#message').html(message || '');
        modal.find('#amount').attr('value', (('' + amount) || '0.0').split('.')[0]);
        modal.find('.btn-update-giftcertificate-incart').attr('data-uuid', uuid);
        modal.find('.btn-update-giftcertificate-incart').data('uuid', uuid);
    });

    // Handle warranty products
    initToggleWarrantyProductLineItem();

    //Handle treatment kit
    initToggleTreatmentKit();

    $('body').on('click', '.create-quote-button', function(e) {
        e.preventDefault();
        var url = $('.create-quote-button').attr('href');
        $.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            success: function (data) {
                if (!data.error && data.quote) {
                    window.alert('Successfully created quote: ' + data.quote);
                } else {
                    window.alert('Failed to create quote');
                }
                $.spinner().stop();
            },
            error: function (err) {
                window.alert('Failed to create quote');
                $.spinner().stop();
            }
        });
    });
}

function initToggleWarrantyProductLineItem() {
    $('.remove-warranty-btn').on('click', function (e) {
        e.preventDefault();

        var $this = $(this);
        var productID = $this.data('pid');
        var uuid = $this.data('uuid');

        if (uuid) { //Remove warranty product
            var url = $this.data('removefromcarturl');
            var urlParams = {
                pid: productID,
                uuid: uuid
            };

            url = module.exports.appendToUrl(url, urlParams);

            $('body').trigger('cart:beforeUpdate');

            $.spinner().start();
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    module.exports.updateCartTotals(data.basket, true);
                    module.exports.validateBasket(data.basket);

                    //Update warranty section
                    if (data.renderedTemplate) {
                        $('#warranty-section').html(data.renderedTemplate);
                        $('#warranty-section .tooltip-icon').attr('title', data.warrantyTooltipContent);
                        $('[data-toggle="tooltip"]').tooltip();
                        $(`.associated-warranty-product-container[data-warranty-pid="${productID}"]`).addClass('d-none');
                        initToggleWarrantyProductLineItem();
                    }

                    $('body').trigger('cart:update', [data, uuid]);

                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        module.exports.createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });

            $('.associated-warranty-product-' + productID).addClass('inactive');
        } else { //Add warranty product
            $.spinner().start();

            var addToCartUrl = $this.data('addtocarturl');
            var quantity = $this.data('qty');

            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: {
                    pid: productID,
                    quantity: quantity
                },
                success: function (data) {
                    productBase.methods.handlePostCartAdd(data);
                    $('body').trigger('product:afterAddToCart', data);
                    productBase.methods.miniCartReportingUrl(data.reportingURL);
                    $.spinner().stop();

                    $('#warranty-section').html(data.renderedTemplate);
                    $('#warranty-section .tooltip-icon').attr('title', data.warrantyTooltipContent);
                    $('[data-toggle="tooltip"]').tooltip();
                    initToggleWarrantyProductLineItem();
                    module.exports.updateCartTotals(data.cart, true);
                    $('.associated-warranty-product-' + productID).removeClass('inactive');
                },
                error: function (error) {
                    $.spinner().stop();
                }
            });
        }
    });

    $('.warranty-items-toggle-open').on('click', function(e) {
        e.preventDefault();
        $(this).closest('div').find('.warranty-item-images').removeClass('d-none');
        $(this).closest('div').find('.warranty-items-toggle-close').removeClass('d-none');
        $(this).addClass('d-none');
    });

    $('.warranty-items-toggle-close').on('click', function(e) {
        e.preventDefault();
        $(this).closest('div').find('.warranty-item-images').addClass('d-none');
        $(this).closest('div').find('.warranty-items-toggle-open').removeClass('d-none');
        $(this).addClass('d-none');
    });
}

function initToggleTreatmentKit() {
    $('.add-treatment-kit-btn').on('click', function (e) {
        e.preventDefault();
        var $this = $(this);
        var productID = $this.data('pid');
        var uuid = $this.data('uuid');

        if (!uuid) { //Add treatment kit
            $.spinner().start();

            var addToCartUrl = $this.data('addtocarturl');

            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: {
                    pid: productID,
                    quantity: 1
                },
                success: function (data) {
                    productBase.methods.handlePostCartAdd(data);
                    $('body').trigger('product:afterAddToCart', data);
                    productBase.methods.miniCartReportingUrl(data.reportingURL);
                    $.spinner().stop();

                    $('#treatment-kit-section').html($(data.treatmentRenderedTemplate).html());
                    $('.treatment-kit-content').html(data.treatmentKitContent);

                    if (data.newLineItemTemplate) {
                        $('.cart-shop-item-list-wrapper').first().append(data.newLineItemTemplate);
                    }

                    initToggleTreatmentKit();
                    module.exports.updateCartTotals(data.cart);
                    $('#treatment-kit-status').text($('#treatment-kit-status').data('add'));
                },
                error: function (error) {
                    $.spinner().stop();
                }
            });
        } else { //Remove treatment kit
            var removeFromCartUrl = $this.data('removefromcarturl');
            var productName = $this.data('name');

            module.exports.confirmDelete(removeFromCartUrl, productID, productName, uuid);
        }
    });

    $('.treatment-kit-toggle-open').on('click', function(e) {
        e.preventDefault();
        $(this).addClass('d-none');
        $('.treatment-kit-details').removeClass('d-none');
    });

    $('.treatment-kit-toggle-close').on('click', function(e) {
        e.preventDefault();
        $('.treatment-kit-details').addClass('d-none');
        $('.treatment-kit-toggle-open').removeClass('d-none');
    });
}

function synchronyLoader() {
    $('.syfdymodal').on('click', function(e) {
        $.spinner().start();
    });

    $('#pay-via-mathis-credit').on('click', function(e) {
        $.spinner().start();
    });
}

module.exports = {
    synchronyLoader: synchronyLoader,
    getPidValue: base.getPidValue,
    appendToUrl: base.appendToUrl,
    validateBasket: base.validateBasket,
    updateCartTotals: updateCartTotals,
    createErrorNotification: base.createErrorNotification,
    updateApproachingDiscounts: base.updateApproachingDiscounts,
    updateAvailability: updateAvailability,
    updateProductDetails: base.updateProductDetails,
    confirmDelete: base.confirmDelete,
    init: init
};
