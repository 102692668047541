'use strict';

var baseProduct = require('core/product/base');
var abSlider = require('core/components/slider');

function getQuantityValue(container) {
    var quantity;
    var qtyStepper = container.find('.quantity-stepper');

    if (qtyStepper.length) {
        quantity = qtyStepper.find('input[name="qty-stepper"]').val();
    } else {
        quantity = container.find('.quantity-select').val();
    }

    return quantity;
}

/**
 * Retrieves selected bundled recommendations and updates total and number of items selected
 */
function updateBundleProductsData(e) {
    if (!e) {
        return;
    }

    var $target = $(e.target).length ? $(e.target) : $(e);
    var $selectedMultiProductBundleSection = $target.closest('.multi-product-bundle');
    var $addOnsQtyFields = $selectedMultiProductBundleSection.find('input:checked').closest('.product-tile-bundle').find('input[name="qty-stepper"]');
    var addOnsQty = 0;
    $addOnsQtyFields.each((i, ele) => {
        addOnsQty += Number($(ele).val());
    });
    var productBundlesContainer = $('.product-tile-bundles');
    if (productBundlesContainer == null || productBundlesContainer.length === 0) return;

    // single bundle
    var singleBundle;
    var singleBundleProductSelected = $('.js-product-tile-bundle-radio:checked');
    if (singleBundleProductSelected && singleBundleProductSelected.length > 0) {
        var bundleProductTile = singleBundleProductSelected.closest('.product-tile-bundle');
        var quantity = Number(getQuantityValue(bundleProductTile));

        singleBundle = {
            priceTotal: Number(bundleProductTile.attr('data-price')) * quantity,
            itemsTotal: quantity
        };
    }

    // multi bundle
    var multiBundle;
    var $allBundlesContainer= $target.closest('.product-tile-bundles');
    var multiBundleProductsSelected = $allBundlesContainer.find('.js-product-tile-bundle-checkbox:checked');
    if (multiBundleProductsSelected && multiBundleProductsSelected.length > 0) {
        multiBundle = {
            priceTotal: 0,
            itemsTotal: 0
        };
        multiBundleProductsSelected.each(function () {
            var bundleProductTile = $(this).closest('.product-tile-bundle');
            var quantity = Number(getQuantityValue(bundleProductTile));

            multiBundle.priceTotal += Number(bundleProductTile.attr('data-price')) * quantity;
            multiBundle.itemsTotal += quantity;
        });
    }
    // sleep bundle
    var isSleepBundle = $('.product-tile-bundles .multi-product-bundle').data('issleepbundle');
    var categoryName = $('#category-name').val();
    var marketplaceProduct = $('#marketplace-product').val();

    var data = {
        mainProductQty: ($('.qty-cart-container').length > 0) ? Number(getQuantityValue($('.qty-cart-container'))) : 1,
        singleBundle: singleBundle ? JSON.stringify(singleBundle) : null,
        multiBundle: multiBundle ? JSON.stringify(multiBundle) : null,
        isSleepBundle: (typeof isSleepBundle != 'undefined' && isSleepBundle) ? true : false,
        sectionTotal: addOnsQty,
        categoryName: categoryName,
        marketplaceProduct: marketplaceProduct
    }

    $.ajax({
        url: $('.update-bundle-url').val(),
        method: 'POST',
        data: data,
        success: function (response) {
            var singleBundleContainer = $('.js-single-product-bundle');
            var sectionHeadingText = ($selectedMultiProductBundleSection.data() && $selectedMultiProductBundleSection.data().isbasebundle) ? response.resources.singleBundle : response.resources.multiBundle;

            $('.js-bundleTotal').show();
            $('.js-add-to-cart-btn').text(response.resources.addToCartButton);
            $allBundlesContainer.attr('addtocart-text-bundle', response.resources.addToCartButton);

            // Single Bundle
            if (response.singleBundle) {
                singleBundleContainer.find('.js-bundleHeaderText').text(response.singleBundle.itemsTotal);
                singleBundleContainer.find('.js-bundleTotal').text(response.singleBundle.priceTotal);
            } else if (!response.multiBundle) {
                singleBundleContainer.find('.js-bundleHeaderText').text(sectionHeadingText);
                singleBundleContainer.find('.js-bundleTotal').text('');
            }

            // Multi Bundle
            if (response.clearSectionHeading){
                $selectedMultiProductBundleSection.find('.js-bundleHeaderText').text(sectionHeadingText);
                $selectedMultiProductBundleSection.find('.js-bundleTotal').text('');
            } else if (response.multiBundle) {
                $selectedMultiProductBundleSection.find('.js-bundleHeaderText').text(response.multiBundle.sectionTotal);
                $selectedMultiProductBundleSection.find('.js-bundleTotal').text(response.multiBundle.priceTotal);
            } else if (response.isSleepBundle) {
                if (response.resources.sleepBundle) {
                    $selectedMultiProductBundleSection.find('.js-bundleHeaderText').text(response.resources.sleepBundle);
                } else {
                    $selectedMultiProductBundleSection.find('.js-bundleHeaderText').text(response.resources.multiBundle);
                }

                $selectedMultiProductBundleSection.find('.js-bundleTotal').text('');
            }
        }
    });
}

/**
 * Triggers recommended bundle product updates
 */
function triggerBundleProductsUpdate() {
    $(document).on('change', '.js-product-tile-bundle-checkbox, .js-product-tile-bundle-radio', function (e) {
        updateBundleProductsData(e);
    });
}

/**
 * Calculate and set the maximum height for the other elements
 */
function setMaxHeightforElements(eleObject) {
    if ($(window).width() > 768) {
        $.each(eleObject, function(index, element ) {
            $(element).css('height', 'auto');
            var maxHeight = Math.max.apply(null, $(element).map(function () {
                return $(this).outerHeight();
            }).get());
            $(element).css('height', maxHeight);
        });
    }
}


var cachedWidth = $(window).width();
$(window).off().on('resize',function() {
    var activeBundles = $('.multi-product-bundle.active');
    if ($(window).width() > 768) {
        if($(window).width() !== cachedWidth){
            cachedWidth = $(window).width();
            $.each(activeBundles, function(index, element ) {
                var thisBundle = $(element).find('.slider-container');
                thisBundle.find('.product-tile-bundle__name, .bundle-variation-qty-container, .bundle-image-container').css('height', '');
                setTimeout(function () {
                    setMaxHeightforElements([thisBundle.find('.product-tile-bundle__name'),
                                            thisBundle.find('.bundle-variation-qty-container'),
                                            thisBundle.find('.bundle-image-container')
                                        ]);
                }, 500);
            });
        }
    } else {
        activeBundles.find('.product-tile-bundle__name, .bundle-variation-qty-container, .bundle-image-container').css('height', '');
    }
});

/**
 * Initiates recommended bundle section slider
 */
function initBundleProductSlider() {
    $(document).on('click', '.multi-product-bundle .card-title', function (e) {
        // bundled collapisble area is expanded
        var bundleParent = $(this).parents('.multi-product-bundle');
        if (bundleParent.hasClass('active')) {
            var $sliderContainer = bundleParent.find('.slider-container.delay-init');
            if ($sliderContainer) {
                $sliderContainer.removeClass('delay-init');
                abSlider.initializeSliders($sliderContainer.parent());
            }
            
            // Calculate and set the maximum height for the other elements
            var timer = variantClick ? 1000 : 30;
            setTimeout(function () {
                variantClick = false;
                var thisBundle = $(e.target).closest(bundleParent).find('.slider-container');
                setMaxHeightforElements([thisBundle.find('.product-tile-bundle__name'),
                                        thisBundle.find('.bundle-variation-qty-container'),
                                        thisBundle.find('.bundle-image-container')
                                    ]);
            }, timer);
        }
    });
}

/**
 * Updates the Recommendation Product Bundle HTML
 * Resets 'Add To Cart' button text
 * @param {String} recommendationBundlesHTML - HTML of the recommendation bundle product area
 * @param {Object} resources - Resource message text
 */
var variantClick = false;
function updateRecommendationProductBundles(recommendationBundlesHTML, resources) {
    if (!recommendationBundlesHTML) return;

    $('.product-tile-bundles').html(recommendationBundlesHTML);
    $('.js-add-to-cart-btn').text(resources.addToCart);

    // init slider
    abSlider.initializeSliders($('.bundle-tile-body .slider-container').parent());

    // init quantity stepper only on bundle product tiles
    var $steppers = $('.bundle-tile-body').find('.quantity-stepper');
    if ($steppers.length) {
        $steppers.each((index, stepper) => {
            var $stepper = $(stepper);
            baseProduct.methods.updateQuantityStepperDisabledStates($stepper);
            baseProduct.methods.bindQuantityStepperButtons($stepper);
            baseProduct.methods.bindQuantityStepperInput($stepper);
        });
    }

    // Open sleep bundle section
    if ($('.js-multi-product-bundle').length > 0) {
        variantClick = true;
        $('.js-multi-product-bundle .title').trigger('click');
    }
}

module.exports = {
    methods: {
        updateBundleProductsData: updateBundleProductsData,
        getQuantityValue: getQuantityValue
    },
    triggerBundleProductsUpdate: triggerBundleProductsUpdate,
    initBundleProductSlider: initBundleProductSlider,
    updateRecommendationProductBundles: updateRecommendationProductBundles
}
