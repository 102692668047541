
'use strict';

function check(e, next) {
    if (typeof grecaptcha !== 'undefined' && grecaptcha) {
        grecaptcha.ready(function() {
            var actionName = 'submit' + (e && e.target ? (e.target.id + e.target.className).replace(/[^a-zA-Z0-9]+/g, '') : '');
            grecaptcha.execute(window.CachedData.recaptchaSiteKey, {action: actionName}).then(function(token) {

                var recaptchaTokenInput = $("<input type='hidden' class='form-control' id='recaptchaToken' name='dwfrm_recaptcha_recaptchaToken' value=" + token + ">");
                var recaptchaActionInput = $("<input type='hidden' class='form-control' id='recaptchaAction' name='dwfrm_recaptcha_recaptchaAction' value=" + actionName + ">");

                if (typeof next.data == 'string') {
                    next.data += next.data.length === 0 ? '' : '&';
                    next.data += $.param(recaptchaTokenInput) + '&' + $.param(recaptchaActionInput);
                } else {
                    next.data = next.data || {};
                    next.data['dwfrm_recaptcha_recaptchaToken'] = token;
                    next.data['dwfrm_recaptcha_recaptchaAction'] = actionName;
                }

                $.ajax(next);
            });
        });
    } else {
        $.ajax(next);
    }
}

module.exports = {
    check: check
}
