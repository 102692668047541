'use strict';

function pushEvent(event, data, isEcommerceData) {
    window.dataLayer = window.dataLayer || [];
    var eventObjToPush = {
        'event': event
    };
    if (isEcommerceData) {
        Object.assign(eventObjToPush, data);
    } else {
        Object.assign(eventObjToPush, {
            'data': data
        });
    }
    dataLayer.push(eventObjToPush);
}

function init() {
    const appointmentLinks = ['design-services', 'book-design-appointment'];

    $('body').on('click', (element) => {
        var $element = $(element.target);

        if ($element.length > 0) {
            var linkAttrName = 'href';
            // if current element does not have href attr, its parent might have it or it's a button
            if (!$element.attr('href')) {
                if ($element.attr('action')) {
                    linkAttrName = 'action';
                } else if ($element.parents('a').length > 0) {
                    $element = $($element.parents('a')[0]);
                } else if ($element.parents('button').length > 0) {
                    $element = $element.parents('button').length > 0 ? $($element.parents('button')[0]): $element;
                    linkAttrName = 'action';
                }
            }

            if ($element.attr(linkAttrName)) {
                if (window.CachedData && window.CachedData.gtmData) {
                    if ('applyForFinancingUrls' in window.CachedData.gtmData) {
                        var applyForFinancingUrls = window.CachedData.gtmData.applyForFinancingUrls;
                    }
                    if ('waysToShopId' in window.CachedData.gtmData) {
                        var waysToShopId = window.CachedData.gtmData.waysToShopId;
                    }
                }

                // "Ways to Shop" module could include appointment schedule, hence in a separate if-statement
                if (waysToShopId && $element.parents(`#${waysToShopId}`).length > 0) {
                    pushEvent('waysToShop', {
                        'eventLabel': $.trim($element.text()),
                        'eventAction': $element.attr(linkAttrName),
                        'eventCategory': 'ways to shop'
                    });
                }

                if (window.isMobile() && $element.attr(linkAttrName).includes('tel:')) {
                    pushEvent('clickToCall', {
                        'eventLabel': $.trim($element.text()),
                        'eventAction': 'mobile click to call',
                        'eventCategory': 'click to call'
                    });
                } else if ($element.hasClass('affirm-modal-trigger') || $element.attr('id') == 'pay-via-mathis-credit'
                    || (['apply now', 'apply today'].some((str) => $.trim($element.text()).toLowerCase().includes(str))
                    && applyForFinancingUrls && applyForFinancingUrls.some((url) => $element.attr(linkAttrName) && $element.attr(linkAttrName).includes(url)))) {
                    // 1st&2nd conditions: putting the tracker on the link/btn that opens the affirm/synchrony iframe, since we can't track inside iframes
                    // 3rd condition: check link/button text includes specified string and url
                    pushEvent('applyForFinancing', {
                        'eventCategory': 'apply for financing',
                        'eventAction': document.location.href,
                        'eventLabel': $.trim($element.text())
                    });
                } else if ($element.attr(linkAttrName).includes('1000-survey')) {
                    var dataObject = dataLayer && dataLayer.find(obj => 'loggedInState' in obj && 'email' in obj);
                    pushEvent('survey$1000', {
                        'eventLabel': dataObject && dataObject.loggedInState && dataObject.customerNo ? dataObject.customerNo : '',
                        'eventAction': document.location.href,
                        'eventCategory': '$1000 survey'
                    });
                } else if (appointmentLinks.some((str) => $element.attr(linkAttrName) && $element.attr(linkAttrName).includes(str))) {
                    // on origin page, we save the clicked link's name
                    window.localStorage.setItem('appointment_link_name', $.trim($element.text()));
                } else { // since Calendly is different platform, we are pushing event before it opens Calendly page
                    var targetUrl = $element.attr('href');
                    if (targetUrl && targetUrl.includes('calendly.com')) {
                        const segments = new URL(targetUrl).pathname.split('/');
                        const designerId = segments.pop() || segments.pop();

                        pushEvent('bookAppointment', {
                            'eventLabel': $.trim($element.text()),
                            'eventAction': 'Calendly - ' + designerId,
                            'eventCategory': "book an appointment"
                        });

                        var bookEventObjToPush = {
                            'event': 'book_click',
                            'link_text': $.trim($element.text()),
                            'link_url': targetUrl,
                            'link_type': "book"
                        };
                        dataLayer.push(bookEventObjToPush);
                    } else if (targetUrl && targetUrl.includes('mailto:')) {
                        var emailEventObjToPush = {
                            'event': 'email_click',
                            'link_text': $.trim($element.text()),
                            'link_url': targetUrl,
                            'link_type': "email"
                        };
                        dataLayer.push(emailEventObjToPush);
                    }
                }
            }
        }
    });

    $('body').on('gtm:pushData', function (e, data , isAjax) {
        var gtmData = data;
        var email = $('#footerEmailSignup').val();
        if (email !== '') {
            window.dataLayer.push({ event: 'identify', userData: {customerEmail: email}});
        }
        if (gtmData) {
            if (!(typeof gtmData === 'object')) {
                gtmData = JSON.parse(gtmData);
            }
            window.dataLayer = window.dataLayer || [];
            if (Array.isArray(gtmData)) {
                dataLayer.push(...gtmData);
            } else {
                if(isAjax){
                    gtmData.isAjax = true;
                }
                dataLayer.push(gtmData);
            }
        }
    });

    $('body').on('gtm:placeorder-data', function (e, data) {
        var gtmData;
        try {
            if(data){
                if(typeof data === 'object'){
                    gtmData = JSON.parse(JSON.stringify(data)); 
                }
                var $elebtn = $('.next-step-button');
                var placeOrderBtn = $elebtn.find('#submit-order');
               if(gtmData && placeOrderBtn && placeOrderBtn.length){
                    gtmData.checkoutMetaData.step = 5;
                    gtmData.checkoutMetaData.stepInfo = 'placeorder';
                    gtmData = JSON.stringify(gtmData);
                    placeOrderBtn.attr('data-gtmdata', gtmData);
               }
            }
        } catch (error) {
            
        }
    });

    $(document).on('click', '.suggestions-item > a', function(e){
        var $targetObj = $(this);
        var catalog =  { "name": "mathishome" };
        var parentDiv =  $targetObj.closest('div');
        if(parentDiv.attr('id') && parentDiv.attr('id').includes('content')){
            catalog = { "name": "content_en" };
        };
        var searchData = {
            event: 'suggest',
            br_suggestData :{
                aq: $targetObj.closest('form').find('input[name=q]').val() || '',
                q: $targetObj.text().trim(),
                catalogs: [catalog]
            }
        }
        $('body').trigger('gtm:pushData', searchData);
    });

    $('form.form-visual-search').on('submit', function(e){
        var $targetObj = $(this);
        var search_query = $targetObj.find('input.search-field').val();
        if(search_query){
            var searchData = {
                event: 'brsearch',
                br_searchData :{
                    q: search_query
                }
            }
            $('body').trigger('gtm:pushData', searchData);
        }
    });


    $(document).on('click', '.next-step-button', function (e) {
        var $elebtn = $(this);
        var placeOrderBtn = $elebtn.find('#submit-order');
        var gtmdata = placeOrderBtn && placeOrderBtn.length && placeOrderBtn.attr('data-gtmdata') ? placeOrderBtn.attr('data-gtmdata') : null;
        var isPlaceorder = window.location.href.indexOf('placeOrder')
        if(gtmdata && isPlaceorder > 0){
            $('body').trigger('gtm:pushData',gtmdata);
        }
    });

    // on destination page, we fetch the saved name and current page's name and push the event
    $(document).ready(() => {
        const currentPath = document.location.pathname;
        if (appointmentLinks.some((str) => currentPath && currentPath.includes(str))) {
            pushEvent('bookAppointment', {
                'eventLabel': window.localStorage.getItem('appointment_link_name'),
                'eventAction': document.title,
                'eventCategory': "book an appointment"
            });
        }
    // if page is PLP
    if (pageAction == 'searchshow') {
        let itemList = [];
        let $plpProductTiles = $('.product-grid .product');
        $plpProductTiles.each((index, el) => {
            let gtmData = $(el).attr('data-gtmdata');
            gtmData = typeof gtmData == 'string' ? JSON.parse(gtmData) : '';

            if (gtmData) {
                itemList.push({
                    'affiliation': gtmData.affiliation ? gtmData.affiliation : '',
                    'coupon': gtmData.coupon ? gtmData.coupon : '',
                    'discount': gtmData.discount ? gtmData.discount : '',
                    'index': index,
                    'is_bundle_product': gtmData.is_bundle_product ? gtmData.is_bundle_product : false,
                    'item_badge': gtmData.item_badge ? gtmData.item_badge : '',
                    'item_brand': gtmData.item_brand ? gtmData.item_brand : '',
                    'item_category': gtmData.item_category ? gtmData.item_category : '',
                    'item_id': gtmData.item_id ? gtmData.item_id : '',
                    'item_name': gtmData.item_name ? gtmData.item_name : '',
                    'item_variant': gtmData.item_variant ? gtmData.item_variant : '',
                    'price': gtmData.price ? gtmData.price : '',
                    'promotion_id': gtmData.promotion_id ? gtmData.promotion_id : '',
                    'promotion_name': gtmData.promotion_name ? gtmData.promotion_name : '',
                    'quantity': 1,
                    'currency': gtmData.currency ? gtmData.currency : ''
                });
            }
        });
        pushEvent('view_item_list', {
            'ecommerce': {
                'items': itemList
            }
        }, true);
    }
    });
}

function bloomreachRecPixelTrack(thisCarousel, target, trackType) {
    var carouselHasData = thisCarousel.find('.slider-container') && thisCarousel.find('.slider-container').length;
    if(carouselHasData) {
        var wrid = 'br_ext', wid = 'br_ext', wty = 'br_ext';
        var searchParams = new URLSearchParams(window.location.search);
        var wq = searchParams.has('q') ? searchParams.get('q') : '';

        var productId;
        if(target) {
            productId = $(target).closest('.product-tile').attr('data-pid');
        }

        if(thisCarousel.hasClass('bloomreach-widget-recommendations')) {
            wrid = thisCarousel.find('.widgetrid').val();
            wid = thisCarousel.find('.widgetID').val();
            wty = thisCarousel.find('.widgetType').val();
        }
        if(trackType == 'viewEvent') {
            var viewRecoEventData = {
                event: 'brviewreco',
                br_recoInfo: {
                    wrid: wrid,
                    wq: wq,
                    wid: wid,
                    wty: wty
                }
            }
            $('body').trigger('gtm:pushData', viewRecoEventData);
        } else if(trackType == 'clickEvent') {
            var clickRecoEventData = {
                event: 'brclickreco',
                br_recoInfo: {
                    wrid: wrid,
                    wq: wq,
                    wid: wid,
                    wty: wty,
                    item_id: productId
                }
            }
            $('body').trigger('gtm:pushData', clickRecoEventData);
          
        }
    }
}

$.fn.isInViewport = function() {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();
    var viewportTop = $(window).scrollTop() - 100;
    var viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
};
 
function fetchVisibleElements() {
	$('.recom-bloomreach-px-track-js').each(function() {
        var thisSlider = $(this);
		if (thisSlider.isInViewport() && !thisSlider.attr('pixel-track')) {
            bloomreachRecPixelTrack(thisSlider, '', 'viewEvent');
            thisSlider.attr('pixel-track', 'tracked');
		}
	});
}

module.exports = {
    init: init,
    viewEventTrack: function () {
        $(window).on('scroll', function() {
            fetchVisibleElements();
        });
    },
    //bloomreach click event track
    clickEventTrack: function () {
        $(document).on('click', '.recom-bloomreach-px-track-js .product-tile a', function(e) {
            var thisSlider = $(this).closest('.recom-bloomreach-px-track-js');
            bloomreachRecPixelTrack(thisSlider, e.target,  'clickEvent');
        });
    },
    logoutEvent: function () {
        $(document).on('click', '.login-logout-link', function() {
            var logoutObj = {};
            logoutObj.event = 'logout';
            logoutObj.method = 'email';
            logoutObj.login_status = 'logged out';
            $('body').trigger('gtm:pushData', logoutObj);
        });
    },
    searchPaginationEvent: function () {
        $(document).on('click', '.pagination a', function(e) {
            var $this = $(this);
            var paginationObj = {};
            paginationObj.event = 'search_pagination';
            paginationObj.action = $this[0].className;
            paginationObj.link_text = $this[0].innerText;
            paginationObj.link_url = $this[0].href;
            $('body').trigger('gtm:pushData', paginationObj);
        });
    },
    searchResultsClickEvent: function () {
        $(document).on('click', '.product', function(e) {
            var $this = $(this);
            var gtmData = $this[0].dataset.gtmdata;
            gtmData = typeof gtmData == 'string' ? JSON.parse(gtmData) : '';
            var isSearchPage = $('.grid-tile').data('issearchpage');
            if (isSearchPage) {
                var searchResultObj = {};
                searchResultObj.event = 'search_results_click';
                searchResultObj.link_text = gtmData.item_name ? gtmData.item_name : '';
                searchResultObj.link_url = $this[0].dataset.href;
                $('body').trigger('gtm:pushData', searchResultObj);
            }

            let itemList = [];
            if (gtmData) {
                itemList.push({
                    'affiliation': gtmData.affiliation ? gtmData.affiliation : '',
                    'coupon': gtmData.coupon ? gtmData.coupon : '',
                    'discount': gtmData.discount ? gtmData.discount : '',
                    'is_bundle_product': gtmData.is_bundle_product ? gtmData.is_bundle_product : false,
                    'item_badge': gtmData.item_badge ? gtmData.item_badge : '',
                    'item_brand': gtmData.item_brand ? gtmData.item_brand : '',
                    'item_category': gtmData.item_category ? gtmData.item_category : '',
                    'item_id': gtmData.item_id ? gtmData.item_id : '',
                    'item_name': gtmData.item_name ? gtmData.item_name : '',
                    'item_variant': gtmData.item_variant ? gtmData.item_variant : '',
                    'price': gtmData.price ? gtmData.price : '',
                    'promotion_id': gtmData.promotion_id ? gtmData.promotion_id : '',
                    'promotion_name': gtmData.promotion_name ? gtmData.promotion_name : '',
                    'quantity': 1,
                    'currency': gtmData.currency ? gtmData.currency : ''
                });
            }
            pushEvent('select_item', {
                'ecommerce': {
                    'items': itemList
                }
            }, true);

        });
    },
    viewModelEvent: function () {
        $(document).on('shown.bs.modal', '.modal', function () {
            var $this = $(this);
            var viewModelObj = {};
            viewModelObj.event = 'view_modal';
            viewModelObj.title = $this[0].id;
            $('body').trigger('gtm:pushData', viewModelObj);
        });
    },
    clickModelEvent: function () {
        $(document).on('hidden.bs.modal', '.modal', function () {
            var $this = $(this);
            var clickModelObj = {};
            clickModelObj.event = 'click_modal';
            clickModelObj.modal_title = $this[0].id;
            clickModelObj.action = 'modal closed';
            clickModelObj.link_text = '';
            clickModelObj.link_url = '';
            $('body').trigger('gtm:pushData', clickModelObj);
        });
    },
    clickModel: function () {
        $(document).on('click', '.modal', function () {
            var $this = $(this);
            var clickModelObj = {};
            clickModelObj.event = 'click_modal';
            clickModelObj.modal_title = $this[0].id;
            clickModelObj.action = 'modal clicked';
            clickModelObj.link_text = '';
            clickModelObj.link_url = '';
            $('body').trigger('gtm:pushData', clickModelObj);
        });
    },
    homePageBannerEvent: function () {
        $('.homepage-banner-tracking').on('click', '.hero-background-link', function () {
            var link_url = $(this).attr('href');
            var homePageBannerObj = {};
            homePageBannerObj.event = 'banner_click';
            homePageBannerObj.banner_title = $('.homepage-banner-title-tracking')[0].innerText ? $('.homepage-banner-title-tracking')[0].innerText : 'HomePage Banner';
            homePageBannerObj.link_url = link_url;
            homePageBannerObj.link_text = 'HomePage Banner';
            $('body').trigger('gtm:pushData', homePageBannerObj);
        });
    }
};

