'use strict';
var siteIntegrations = require('core/integrations/siteIntegrationsUtils');
var toggleObject = siteIntegrations.getIntegrationSettings();

var exports = $.extend({}, require('core/product/base'));
var recommendationBundle = require('./recommendationBundle');
var debounce = require('lodash/debounce');

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
// Replaces rvw_autobahn_core
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVariant;
    if (response.product.variationAttributes) {
        exports.methods.updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
        isVariant = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVariant) {
            $productContainer.parent('.bonus-product-item').data('pid', response.product.id);
            $productContainer.parent('.bonus-product-item').data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update primary images
    var productResponse = response.product;
    var $primaryImagesContainer = $productContainer.find('.primary-images');
    var $oldWishlistIcon = $productContainer.find('div.slide a.wishlist');

    exports.methods.createSlider(productResponse, $primaryImagesContainer);

    // Update product name and short description
    var collapsibleContent = $productContainer.find('.collapsible-content-group');
    var productName = productResponse.productName;
    $productContainer.find('.product-name').html(productName);
    collapsibleContent.find('.overview-product-name').html(productName);
    collapsibleContent.find('.product-description .short-descr-content').html(productResponse.shortDescription);

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $('.prices .price .pricing-everyday .display-price', $productContainer).length
            ? $('.prices .price .pricing-everyday .display-price', $productContainer)
            : $('.prices .price .pricing-everyday .display-price');

        if (response.product && response.product.price) {
            if (response.product.price.html) {
                $priceSelector.replaceWith($(response.product.price.html).find('.sales .pricing-everyday .display-price'));
                if (response.product.promotions) {
                    var $newStrikeThroughPrice = $(response.product.price.html).find('.strike-through-wrapper .display-price');
                    $('.strike-through-wrapper .display-price').replaceWith($newStrikeThroughPrice);
                    $('.product-data .standard-from-pricing').text('From');
                    $('.product-data .prices .price .sales').addClass('has-sale');
                    $newStrikeThroughPrice.appendTo('.product-data .standard-from-pricing');
                    $('<span class="promotion"><button type="button" data-toggle="modal" data-target="#promotionModal" class="class-details-button">Details</button></span>').appendTo('.product-data .standard-from-pricing');
                }
            }

            if (response.product.price.sales) {
                updateFinancingModal(response.product.price.sales.value, response.product.price.sales.formatted);
            }
        }
    }

    // Update member pricing
    var pricingMember = $productContainer.find('.pricing-member');
    if (response && response.product && response.product.membership && response.product.membership.formattedPrice) {
        pricingMember
            .removeClass('d-none')
            .find('.display-price')
            .css('font-weight', '700')
            .text(response.product.membership.formattedPrice);
        $('.prices .price .pricing-everyday .display-price').css('font-weight', '400');
    } else {
        pricingMember
            .addClass('d-none')
            .find('.display-price')
            .text(response.product.membership.formattedPrice);
    }

    // Update promotions
    $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);
    $productContainer.find('.promotion-tooltip-wrapper').empty().html(response.product.promotionTooltipHtml);
    exports.methods.updateAvailabilityProcess(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty().html(exports.methods.getAttributesHtml(response.product.attributes));

    // Update wishlist
    if ($oldWishlistIcon && $oldWishlistIcon.length) {
        var $newWishlistIcon = $($oldWishlistIcon[0]);
        $newWishlistIcon.attr('data-wishlistpid', response.product.wishlistpid);

        //Make heart icon accurate
        var wishlist = require('core/wishlist/wishlist');
        wishlist.updateWishlistLinkData($newWishlistIcon);

        var $newSliderMainImages = $productContainer.find('div.primary-images-main div.slide img, div.primary-images-main div.slide .cloudimage-360');
        $newSliderMainImages.each((_i, newImage) => {
            var $newImage = $(newImage);
            $newImage.after($newWishlistIcon.clone(true));
        });
    }

    // Update promotions details link on pdp only
    if ($('.display-price').length > 1 && $('.page[data-action="Product-Show"]').length > 0) {
        if ($('.class-details-button').length == 0) {
            $('.sales.has-sale').after('<span class="promotion"><button type="button" data-toggle="modal" data-target="#promotionModal" class="class-details-button">Details</button></span>')
        }
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
// triggger quantity functionality for pdp mirakl product
function enableQuantitySteppers($context = $('body')) {
    var scope = this;
    var $steppers = $context.find('.quantity-stepper');
    if ($steppers && $steppers.length) {
        $steppers.each((index, stepper) => {
            var $stepper = $(stepper);
            exports.methods.updateQuantityStepperDisabledStates($stepper);
            exports.methods.bindQuantityStepperButtons($stepper);
            exports.methods.bindQuantityStepperInput($stepper);
        });
    }
}

function getGtmProductData(product) {
    var gtmData = product.gtmData;
    gtmData = typeof gtmData == 'string' ? JSON.parse(gtmData) : gtmData;

    var itemList = [];
    if (gtmData) {
        itemList.push({
            'affiliation': gtmData.affiliation ? gtmData.affiliation : '',
            'coupon': gtmData.coupon ? gtmData.coupon : '',
            'discount': gtmData.discount ? gtmData.discount : '',
            'is_bundle_product': gtmData.is_bundle_product ? gtmData.is_bundle_product : false,
            'item_badge': gtmData.item_badge ? gtmData.item_badge : '',
            'item_brand': gtmData.item_brand ? gtmData.item_brand : '',
            'item_category': gtmData.item_category ? gtmData.item_category : '',
            'item_id': gtmData.item_id ? gtmData.item_id : '',
            'item_name': gtmData.item_name ? gtmData.item_name : '',
            'item_variant': gtmData.item_variant ? gtmData.item_variant : '',
            'price': gtmData.price ? gtmData.price : '',
            'promotion_id': gtmData.promotion_id ? gtmData.promotion_id : '',
            'promotion_name': gtmData.promotion_name ? gtmData.promotion_name : '',
            'quantity': 1,
            'currency': gtmData.currency ? gtmData.currency : ''
        });
    }
    return itemList;
}

// Replaces rvw_autobahn_core
function attributeSelect(selectedValueUrl, $productContainer) {
    if (selectedValueUrl) {
        var isProductTileBundle = $productContainer.hasClass('product-tile-bundle');
        $('body').trigger('product:beforeAttributeSelect',
            { url: selectedValueUrl, container: $productContainer });

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                if (isProductTileBundle) {
                    handleBundleTileVariantResponse(data, $productContainer);
                } else {
                    handleVariantResponse(data, $productContainer);
                }
                if (data && data.product && data.product.id) {
                    var itemList = getGtmProductData(data.product);
                    var eventObjToPush = {
                        "event": "view_item",
                        "ecommerce": {
                            "items": itemList
                        }
                    }
                    window.dataLayer.push(eventObjToPush);
                }
                $('.add-to-cart').removeAttr('disabled');

                if (data && data.product && data.product.readyToOrder) {
                    $('.delivery-block-js').removeClass('d-none');
                    $('.qty-cart-container .quantity').removeClass('d-none');
                    $('.cart-and-ipay').removeClass('no-padding');
                }

                exports.methods.updateOptions(data.product.optionsHtml, $productContainer);
                exports.methods.updateQuantities(data.product.quantities, $productContainer);
                recommendationBundle.updateRecommendationProductBundles(data.recommendationBundlesHTML, data.resources);

                var isMarketplacePDP = $('.product-detail.product-wrapper').attr('marketplace-product');
                if (isMarketplacePDP == 'false' || (isMarketplacePDP == 'true' && !$productContainer.hasClass('product-tile-bundle'))) {
                    $('body').trigger('product:afterAttributeSelect',
                        { data: data, container: $productContainer });
                }
                    // enabled the quantity stepper for mirkle product
                    enableQuantitySteppers();
                $.spinner().stop();
                // Update Product Availability Message for Bloom Reach
                $('#product_availability').val(data.availabilityMessageBR);
            },
            error: function () {
                $.spinner().stop();
            }
        }).done(function () {
            recommendationBundle.methods.updateBundleProductsData($productContainer.find('.js-product-tile-bundle-checkbox'));
        });
    }
}

// Attributes that display in a select dropdown (default)
// Replaces rvw_autobahn_core
function selectAttribute() {
    var scope = this;
    $(document).on('change', 'select[class*="select-"], .options-select', function (e) {
        e.preventDefault();

        var $productContainer = $(this).closest('.set-item');
        var $productTileBundle = $(this).closest('.product-tile-bundle');
        var $productDetail = $(this).closest('.product-detail');

        if ($productTileBundle.length) {
            $productContainer = $productTileBundle;
        } else if ($productDetail.length) {
            $productContainer = $productDetail;
        }

        scope.methods.attributeSelect(e.currentTarget.value, $productContainer);
    });
}

/**
 * Update Add to Cart button text on changing quantity if we have bundle products
 */
function addItemsToCartText(button, oldQty, newQty) {
    if (!button) return false;

    oldQty = parseInt(oldQty);
    newQty = parseInt(newQty);
    var addToCartBtn = button.parents('.pdp-qty-cart-container').find('.js-add-to-cart-btn');
    var btnText = addToCartBtn.text();
    var currentBtnQty = parseInt(btnText.replace ( /[^\d.]/g, '' ));

    if(currentBtnQty) {
        var textSplit = btnText.split(currentBtnQty);
        var newSelectedQty;
        if(oldQty > newQty) {
            newSelectedQty = currentBtnQty - (oldQty - newQty);
        } else {
            newSelectedQty = currentBtnQty + (newQty - oldQty);
        }
        var newBtnText = textSplit[0]+newSelectedQty+textSplit[1];
        addToCartBtn.text(newBtnText);
        // updating with current value
    }
    button.attr('lastSelectedQty', newQty);
}

// Updates quantity select dropdown (default)
// Replaces rvw_autobahn_core
function availability() {
    var scope = this;
    var quantityInput = $('.pdp-qty-cart-container .quantity-select');
    var oldQty = quantityInput.val();
    quantityInput.attr('lastSelectedQty', oldQty);

    $(document).on('change', '.quantity-select', function (e) {
        e.preventDefault();

        var $productTileBundle = $(this).closest('.product-tile-bundle');
        var $productContainer = $(this).closest('.product-detail');
        var $quickviewContainer = $(this).closest('.modal-content').find('.product-quickview');

        if ($productTileBundle.length) {
            $productContainer = $productTileBundle;
        } else if ($quickviewContainer.length) {
            $productContainer = $quickviewContainer;
        }

        if ($('.bundle-items', $productContainer).length === 0) {
            scope.methods.attributeSelect($(e.currentTarget).find('option:selected').data('url'),
                $productContainer);
        } else {
            var newQty = $(this).val();
            oldQty = $(this).attr('lastSelectedQty');
            addItemsToCartText($(this), oldQty, newQty);
        }
    });
}

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
// Replaces rvw_autobahn_core
function getPidValue($el) {
    var pid;
    if ($('#membershipModal').hasClass('show')) {
        pid = $($el).closest('.modal-content').find('.add-to-cart-js').data('pid');
    } else if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text();
    } else  if (($el).hasClass('single-variant-quick-add-to-cart')) {
        pid = $($el).data('pid');
    } else  if (($el).parents('.popover').length) {
        pid = $($el).closest('.product-detail.product-quick-add-to-cart').data('pid');
    } else {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }

    return pid;
}

/*
 * Adds product(s) to the cart
 * Shared across QV and PDP
 */
// Replaces rvw_autobahn_core
function addToCart() {
    var scope = this;

    $(document).on('click', 'button.add-to-cart, button.add-to-cart-global, a.add-to-cart-global', function () {
        var addToCartUrl;
        var pid;
        var pidsObj;
        var setPids;
        var quantity;

        $('body').trigger('product:beforeAddToCart', this);
        var bundleProductsSelected = $('.js-product-tile-bundle-checkbox:checked, .js-product-tile-bundle-radio:checked');
        pid = getPidValue($(this));
        quantity = $(this).hasClass('single-variant-quick-add-to-cart') ? 1 : scope.methods.getQuantitySelected($(this));
        var isPDPdata = $(this).parents().hasClass('product-data');
        var $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            if ($(this).hasClass('single-variant-quick-add-to-cart')) {
                addToCartUrl = $(this).data('url');
            } else {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
                var $productModalbody = $(this).closest('.modal-content');
                addToCartUrl = scope.methods.getAddToCartUrl($productModalbody);
            }
        } else {
            addToCartUrl = scope.methods.getAddToCartUrl($productContainer);
        }

        if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
            setPids = [];

            $('.product-detail').each(function () {
                if (!$(this).hasClass('product-set-detail')) {
                    setPids.push({
                        pid: $(this).find('.product-id').text(),
                        qty: $(this).find('.quantity-select').val(),
                        options: scope.methods.getOptions($(this))
                    });
                }
            });
            pidsObj = JSON.stringify(setPids);
        } else if (bundleProductsSelected.length > 0 && isPDPdata) {
            // Recommended bundle products
            setPids = [];

            // Add landing page product into pidsObj since Cart-AddProduct only adds a single pid or pidsObj
            setPids.push({
                pid: pid,
                qty: getQuantitySelected($(this)),
                options: scope.methods.getOptions($productContainer)
            });

            bundleProductsSelected.each(function () {
                var bundleProductTile = $(this).closest('.product-tile-bundle');
                setPids.push({
                    pid: bundleProductTile.attr('data-pid'),
                    qty: bundleProductTile.find('.quantity-stepper input').val(),
                    options: scope.methods.getOptions(bundleProductTile)
                });
            });

            pidsObj = JSON.stringify(setPids);
        }

        var form = {
            pid: pid,
            pidsObj: pidsObj,
            childProducts: scope.methods.getChildProducts(),
            quantity: quantity
        };

        if (!$('.bundle-item').length) {
            form.options = scope.methods.getOptions($productContainer);
        }

        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    scope.methods.handlePostCartAdd(data);
                    if(data.gtmData){
                        $('body').trigger('gtm:pushData',data.gtmData);
                    }
                    $('body').trigger('product:afterAddToCart', data);
                    $('body').trigger('product:afterAddToCartQuickview', data); //cart page quickview only
                    $.spinner().stop();
                    scope.methods.miniCartReportingUrl(data.reportingURL);
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
// Replaces rvw_autobahn_core
 function getQuantitySelected($el) {
    var qtySelected = getQuantitySelector($el).val();
    if (qtySelected == null) {
        qtySelected = $('#qty-cart-container .quantity-stepper input').val()
    }
    return qtySelected == null ? '1' : qtySelected;
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
// Replaces rvw_autobahn_core
 function getQuantitySelector($el) {
    var quantitySelected;
    if ($el && $('.set-items').length) {
        quantitySelected = $($el).closest('.product-detail').find('.quantity-select');
    } else if ($el && $('.product-bundle').length) {
        var quantitySelectedModal = $($el).closest('.modal-footer').find('.quantity-select');
        var quantitySelectedPDP = $($el).closest('.bundle-footer').find('.quantity-select');
        if (quantitySelectedModal.val() === undefined) {
            quantitySelected = quantitySelectedPDP;
        } else {
            quantitySelected = quantitySelectedModal;
        }
    } else if ($el && $($el).hasClass('product-tile-bundle')) {
        // product recommendation bundle tile
        quantitySelected = $($el).find('.quantity-select');
    } else {
        quantitySelected = $('.qty-cart-container .quantity-select');
    }

    return quantitySelected;
}

/**
 * Product Tile Bundle: Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
 function handleBundleTileVariantResponse(response, $productContainer) {
    if (!response || !$productContainer) return;

    // Update product name
    $productContainer.find('.product-tile-bundle__name').text(response.product.productName);

    // Update product id
    $productContainer.attr('data-pid', response.product.id);

    // Update attributes
    if (response.product.variationAttributes) {
        exports.methods.updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
    }

    // Update image
    var productTileImage = $productContainer.find('.product-tile-bundle__image');
    var mediumImage = response.product.images && response.product.images.medium && response.product.images.medium[0] ? response.product.images.medium[0] : null;
    if (mediumImage) {
        productTileImage.attr('src', mediumImage.url)
            .attr('alt', mediumImage.alt)
            .attr('title', mediumImage.title);
    }

    // Update price
    var price = response.product.price.list ? response.product.price.list.value : response.product.price.sales.value;
    $productContainer.find('.price').replaceWith(response.product.price.html);
    $productContainer.attr('data-price', price);
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
 function processSwatchValues(attr, $productContainer, msgs) {
    if (attr.attributeId == 'color') {
        $productContainer.find('.color-display-value').text(attr.displayValue || '');
        if (!attr.displayValue) {
            var attributeLabel = $productContainer.find('.color-display-value').data('label');
            $productContainer.find('.color-display-value').text(attributeLabel);
        }
    };

    if (attr.attributeId == 'size') {
        $productContainer.find('.non-color-display-value').text(attr.displayValue || '');
    };

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' + attrValue.value.replaceAll('"', '&quot;') + '"]');

        var $swatchButton = $attrValue.parent('button');

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
            $attrValue.attr('selected', 'selected');
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
            $attrValue.removeAttr('selected');
        }

        if (attrValue.url) {
            $swatchButton.attr('data-url', attrValue.url);
        } else {
            $swatchButton.removeAttr('data-url');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable available unavailable out-of-stock');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
        $attrValue.addClass(attrValue.available ? 'available' :  toggleObject.viewOutOfStockItems ? 'out-of-stock' : 'unavailable');

        $attrValue.attr('value', attrValue.url).removeAttr('disabled');
        if (!attrValue.selectable) {
            $attrValue.attr('disabled', true);
        }
    });
}

function uncheckBundleProductsDataOnLoad() {
    $('.product-tile-bundles :input').each(function(){
        $(this).prop('checked', false);
    });
}

function displayReviewLink() {
    $(window).on('load', function() {
        var $link = $('.product-data .pr-snippet-write-review-link');
        try {
            if ($link.text().trim().toLowerCase() !== 'write the first review') {
                $link.hide();
            }
        } catch (e) {
            // this func is doing something very un-important (MB-613), so just n/m
        }
    });
}

/**
 * Updates Ask a Question section header in Power Review
 */
function insertContactButtonsToQA() {
    $(window).on('load', function () {
        var $prDisplayQuestionContainer = $('.pr-qa-display');

        if ($prDisplayQuestionContainer.length > 0 && $prDisplayQuestionContainer.css('display') != 'none') {
            var questionAndAnswerText = {
                title: $('#pr-questiondisplay').data('ask-question-title'),
                chatInfo: $('#pr-questiondisplay').data('info-chat'),
                contactInfo: $('#pr-questiondisplay').data('info-contact')
            };

            // contact buttons
            var $contactButtons = $(`<a class="btn btn-primary icon-call contact-us" href="tel:1-855-294-3434">${questionAndAnswerText.contactInfo}</a>
                <button class="btn btn-primary icon-livechat chat-with-us" type="button" onclick="PodiumWebChat.open()">${questionAndAnswerText.chatInfo}</button>`);

            var $prAskQuestionSection = $prDisplayQuestionContainer.find('.pr-qa-display-ask-question');

            // update and display title
            var askQuestionSectionTitle = questionAndAnswerText.title;
            var $prAskQuestionHeadline = $prDisplayQuestionContainer.find('h1 abbr');
            $prAskQuestionHeadline.text(askQuestionSectionTitle).attr('title', askQuestionSectionTitle);
            $prAskQuestionHeadline.show();

            // move buttons to be inline with Power Review's "Ask Question" button
            var $prAskQuestionButton = $prAskQuestionSection.find('#pr-question-form-link');
            $prAskQuestionButton.before($contactButtons);

            // toggle buttons and title visibility when "Ask Question" button is clicked
            $prAskQuestionButton.on('click', function () {
                if ($(this).attr('aria-expanded') == 'true') {
                    $prAskQuestionHeadline.show();
                    $contactButtons.show();
                    $contactButtons.closest('.pr-qa-display-ask-question').css('display', 'flex');
                } else {
                    $prAskQuestionHeadline.hide();
                    $contactButtons.hide();
                    $contactButtons.closest('.pr-qa-display-ask-question').css('display', 'block');
                }
            });

            // listen to any changes to Q&A section and reinsert
            // because PowerReview replaces the some of the template
            // when window resized to certain sizes (desktop vs phone)
            var config = {
                subtree: true,
                childList: true
            };
            var prObserver = new MutationObserver((mutationList, observer) => {
                for (var mutationRecord of mutationList) {
                    if (mutationRecord.addedNodes) {
                        for (var addNode of mutationRecord.addedNodes) {
                            if (addNode.classList.contains('pr-qa-display-ask-question')) {
                                $(addNode).find('#pr-question-form-link').before($contactButtons);
                            }
                        }
                    }
                }
            });
            prObserver.observe($prDisplayQuestionContainer[0], config);
        }
    });
}

function updateFinancingModal(total, formattedTotal) {
    var $financingCallout = $('.js-synchronyCTA-financing');
    var $financingModal = $('.modal.dbuy-modal');
    var financingPeriod = $financingCallout.attr('data-period');
    var formattedMonthlyInstallment = '$' + Math.ceil(total / financingPeriod).toFixed(2);
    $financingCallout.find('.synchronyCTA-payment').html(formattedMonthlyInstallment);
    $financingModal.find('.dbuy-modal-total').html(formattedTotal);
    $financingModal.find('.dbuy-modal-installment').html(formattedMonthlyInstallment);
    
    // changes done for MH-69 variation issue.
    $('#product-content').empty().html(total);
}

function populateSelectedOption() {
    $(document).ready(function () {
        $('.product-tile-bundles select').each(function () {
            var select = $(this);
            var selectedValue = select.find('option[selected]').val();

            if (selectedValue) {
                select.val(selectedValue);
            } else {
                select.prop('selectedIndex', 0);
            }
        });
    });
}

function updateAddToCart() {
    $('body').on('product:updateAddToCart', function (e, response) {
        var $productContainer = response.$productContainer;
        // update local add to cart (for sets)
        $productContainer.find('button.add-to-cart').attr('disabled', !response.product.readyToOrder);
        // update global add to cart (single products, bundles)
        var $dialog = $(response.$productContainer).closest('.quick-view-dialog');
        if ($dialog.length){
            $dialog.find('.add-to-cart-global, .update-cart-product-global').attr('disabled', !response.product.readyToOrder);
        } else {
            var enable = $productContainer.find('.product-availability').toArray().every(function (item) {
                return $(item).data('ready-to-order');
            });
            $productContainer.find('button.add-to-cart-global').attr('disabled', !enable);
        }
    });
}

function setHeaderHeightForStickyImages() {
    var headerNavHeight = $('.header-nav').outerHeight();

    $('.primary-images .sticky-top').css('top', headerNavHeight + 25);
}

function windowResize() {
    $(window).on('resize', debounce(() => setHeaderHeightForStickyImages(), 100));
}

function windowLoad() {
    $(window).on('load', debounce(() => setHeaderHeightForStickyImages(), 100));
}

// Product badges overlaid on a Youtube iframe can interfere with clicks
function hideBadgesForYoutube() {
    if (window.innerWidth < 769 && $('.main-youtube-container').length > 0) {
        $('.slider *').on('touchend mouseup', function () {
            window.setTimeout(function () {
                if ($('.main-youtube-container').hasClass('tns-slide-active')) {
                    $('.badge-product-container').hide();
                } else {
                    $('.badge-product-container').show()
                }
            }, 300);
        });
    }
}

// Reinit quickview tooltips after variant selection
function reinitTooltipsQuickview() {
    $('body').on('product:afterAttributeSelect', function() {
        $('.quick-view-dialog [data-toggle="tooltip"]').tooltip();
    });
}

// Center "Shop the Collection" section if it has less than 4 items
function centerCollection() {
    var collectionSlider = $('.shop-the-collection .slider');
    var slide = $('.shop-the-collection .slider .slide');
    collectionSlider.on('load', function () {
        var numSlides = $('.shop-the-collection .slider').children("[id^='tns']").length;
        var slideWidth = (100 / numSlides) + '%';
        if (numSlides < 4) {
            // Remove transform and apply appropriate width to collection and slides if above mobile
            if ($(window).width() > 769) {
                collectionSlider.css('transform', '');
                collectionSlider.css('width', '100%');
                slide.css('width', slideWidth);
            } else {
                collectionSlider.css('width', '');
                slide.css('width', '');
            }
        }
    });
    $(window).resize(function () {
        collectionSlider.trigger('load');
    });
    $(window).on('load', function () {
        collectionSlider.trigger('load');
    });
}

// Initialize 350 view
function init360image() {
    $('.cloudimage-360').on('load', function() {
        window.CI360.init();
    });
    $(window).on('load', function() {
        $('.cloudimage-360').trigger('load');
    });
}

function disableDrag() {
    let element = document.querySelector('.thumbnail-slider');
    let observer = new MutationObserver(function () {
        $('.thumbnail-slider').css('transform', 'translate3d(0%, 0px, 0px)');
    });
    let options = {
        attributes: true
    };
    $('.thumbnail-slider').on('load', function () {
        if (window.innerWidth < 769) {
            $('.thumbnail-slider').css('transform', 'translate3d(0%, 0px, 0px)');
            observer.observe(element, options);
        } else {
            observer.disconnect();
        }
    });
    $(window).on('load resize', function () {
        $('.thumbnail-slider').trigger('load');
    });
}

function readStrikethrough() {
    let strikethrough = $('.strike-through-wrapper .display-price');
    strikethrough.focus(function () {
        $('#screenreader-only').text('Deleted price');
    });
    strikethrough.focusout(function () {
        $('#screenreader-only').text('');
    });
}

function thumbnailSliderAccessibility() {
    $(window).on('load', function () {
        $('.thumbnail-slider .tns-item').attr('tabindex', '0');
    });
}

/**
 * Trigger click on links and buttons from enter or spacebar keypress
 */
function linkAccessibility() { 
    $('a, button').keydown(function (e) {
        if (e.which === 32 || e.which === 13) {
            e.preventDefault();
            $(e.target)[0].click();
        }
    });
}

/**
 * Insert ADA attributes for Youtube delete button on PowerReviews Write page
 */
function updatePowerReviewsWrite() {
    if (window.location.href.includes('PowerReviews-Show') || $('#pr-write').length > 0) {
        $('body').on('click', '.pr-youtube-btn', () => {
            $('.pr-media-table .pr-video-form-group .pr-btn-delete').attr({
                'role': 'button',
                'aria-label': 'Delete this YouTube url'
            });
       })
    }
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer, msgs) {
    var $attr = '.custom-select[data-attr="' + attr.id + '"]';
    var $defaultOption = $productContainer.find($attr + '.select-' + attr.id + ' option:first-child');
    $defaultOption.attr('value', attr.resetUrl).attr('disabled', true);

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find($attr + ' [data-attr-value="' + attrValue.value.replaceAll('"', '&quot;') + '"]');
        $attrValue.attr('value', attrValue.url).removeAttr('disabled');
        var currentSelectedOption = $productContainer.find($attr + '.select-' + attr.id + ' option:selected').eq(0);

        if (!attrValue.selectable || !attrValue.available) {
            if (!attrValue.selectable) {
                $attrValue.attr('disabled', true);
            }
            //check if selected value is now unavailable, if so select the default option
            if (currentSelectedOption.data('attr-value') == attrValue.value) {
                $attrValue.removeAttr('selected');
                $($attr).prop('selectedIndex', 0);
            }
            // append a msg to option to tell user its not available with selected options
            $attrValue.html(attrValue.displayValue + msgs.unavailableMsg);
        } else {
            $attrValue.html(attrValue.displayValue);
            if (currentSelectedOption.text() == attrValue.displayValue) {
                $(currentSelectedOption).attr('selected', 'selected');
                $($attr).prop('selectedIndex', $(currentSelectedOption).index());
            }
        }
    });
}

// updates styles
function promoTileStyle() {
    var $productGrid = $('.product-grid');
    var isMobileGridUpdated = false;

    function updatePromoTileStyle() {
        const promoTileClasses = { mobileGrid: '.marketing-tile-m-grid' };

        if ('mobile' == $('.grid-layout-toggle button').attr('data-target')) {
            var currentLayout = $productGrid.attr('data-grid-mobile-view');

            switch (currentLayout) {
                case 'grid': // if grid selected, make container height 100% so content can fill height
                    let $promoTile = $productGrid.find('.experience-component ' + promoTileClasses.mobileGrid);
                    $promoTile.closest('.experience-region').css('height', '100%');
                    $promoTile.closest('.experience-component').css('height', '100%');
                    isMobileGridUpdated = true;
                    break;
                default:
                    if (isMobileGridUpdated) {
                        let $promoTile = $productGrid.find('.experience-component ' + promoTileClasses.mobileGrid);
                        $promoTile.closest('.experience-region').css('height', '');
                        $promoTile.closest('.experience-component').css('height', '');
                    }
                    break;
            }
        }
    }

    $(window).on('load resize', updatePromoTileStyle);
    $productGrid.on('plp:layoutUpdated', updatePromoTileStyle);
}

exports.methods.attributeSelect = attributeSelect;
exports.methods.getQuantitySelected = getQuantitySelected;
exports.methods.getQuantitySelector = getQuantitySelector;
exports.methods.processSwatchValues = processSwatchValues;
exports.methods.processNonSwatchValues = processNonSwatchValues;
exports.methods.updateFinancingModal = updateFinancingModal;
exports.selectAttribute = selectAttribute;
exports.availability = availability;
exports.addToCart = addToCart;
exports.uncheckBundleProductsDataOnLoad = uncheckBundleProductsDataOnLoad;
exports.displayReviewLink = displayReviewLink;
exports.insertContactButtonsToQA = insertContactButtonsToQA;
exports.populateSelectedOption = populateSelectedOption;
exports.updateAddToCart = updateAddToCart;
exports.windowResize = windowResize;
exports.windowLoad = windowLoad;
exports.hideBadgesForYoutube = hideBadgesForYoutube;
exports.reinitTooltipsQuickview = reinitTooltipsQuickview;
exports.centerCollection = centerCollection;
exports.init360image = init360image;
exports.disableDrag = disableDrag;
exports.readStrikethrough = readStrikethrough;
exports.thumbnailSliderAccessibility = thumbnailSliderAccessibility;
exports.linkAccessibility = linkAccessibility;
exports.updatePowerReviewsWrite = updatePowerReviewsWrite;
exports.promoTileStyle = promoTileStyle;
exports.addItemsToCartText = addItemsToCartText;


module.exports = exports;
