'use strict';

var quickView = require('core/product/quickView');
var miraklQuickView = require('mirakl/product/quickView');
var modal = require('core/components/modal');
const { updateAddAllToCart } = require('core/product/detail');

quickView.reInitShowQuickAddToCart = function () {
    $('body').on('search:sort--success search:showMore--success search:filter--success search:pageSize--success', function () {
        $('body').trigger('tooltip:init');
    })
},

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 * @param {string} alternateModalId - Optional - used for wishlist typically to update the modal id
 */
quickView.fillModalElement = function(selectedValueUrl, alternateModalId) {
    var modalId = (alternateModalId) ? alternateModalId : '#quickViewModal';
    var $modal =  $(modalId);
    $modal.find('.modal-body').spinner().start();
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = modal.parseHtml(data.renderedTemplate);
            var headerHtml = parsedHtml.body.find('.modal-header-wrapper').children();
            var powerReviewsHtml = $('#pr-pidRating-' + data.product.sanitizedId).html();
            if(data.gtmData){
                $('body').trigger('gtm:pushData', data.gtmData);
            }

            $modal.find('.modal-header').empty();
            $modal.find('.modal-header').html(headerHtml);
            $modal.find('.modal-body').empty();
            $modal.find('.modal-body').html(parsedHtml.body);

            // Append assets from PDP Gallery Asset IDs attribute
            if ('pdpGalleryAssets' in data.product) {
                var pdpGalleryAssets = data.product.pdpGalleryAssets;
                var $assetSlides = $modal.find('.primary-images-main .pdp-gallery-asset');
                let hasVideo = false;

                $assetSlides.each((index, slide) => {
                    var $slideElement = $(slide);
                    var asset = pdpGalleryAssets[index];
                    if (asset.isVideo) {
                        hasVideo = true;
                    }

                    $.ajax({
                        url: asset.assetRenderUrl,
                        method: 'GET',
                        success: assetData => {
                            var $sliderContainer = $slideElement.closest('.slider-container');
                            $slideElement.html(assetData);

                            // after all assets are added, reinit any necessary javascript
                            if (index + 1 >= pdpGalleryAssets.length) {
                                $('body').trigger('quickview:ready', $(modalId)); // sending custom event for slider init
                                $slideElement.trigger('tooltip:init'); // reinit tooltips in case of hotspot asset

                                if (hasVideo) {
                                    $('body').trigger('ajax:load.ajaxEvents', [$sliderContainer]); // reinit videos in video/index.js
                                    $('body').trigger('slider:videosLoaded'); // trigger custom event set up in bindSliderUpdateEvent
                                }
                            }
                        },
                        error: err => {
                            console.error('There was an issue retrieving this asset: ', err);
                        }
                    });
                });
            }

            var $modalContent = $modal.find('.quick-view-dialog > .modal-content');

            // using children() to avoid also modifying nested dbuy modal
            $modalContent.children('.modal-footer').html(parsedHtml.footer.filter('.quick-view-footer'));
            $modal.find('.modal-header .close .sr-only').text(data.closeButtonText);
            $modal.find('.enter-message').text(data.enterDialogMessage);
            $modal.find(modalId).modal('show');
            $modal.find('.pr-rating-wrapper').html(powerReviewsHtml);

            if (data.dialogTitle) {
                $modalContent.children('.modal-header').prepend('<div class="modal-title">' + data.dialogTitle + '</div>')
            }

            if (data.product.productType === 'set') {
                updateAddAllToCart();
            }

            $('body').trigger('modal:loaded', $(modalId)); // sending custom event for scroll body sizing
            $('body').trigger('quickview:ready', $(modalId)); // sending custom event for slider init
            $('body').trigger('tooltip:init');
            miraklQuickView.updateBuyBox();

            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

quickView.showQuickview = function () {
    $('body').on('click', '.quickview', function (e) {
        e.preventDefault();
        var selectedValueUrl = $(this).closest('button.quickview').attr('href');
        $(e.target).trigger('quickview:show');
        modal.getModalHtmlElement('quickViewModal', 'quick-view-dialog modal-scrollbody');
        quickView.fillModalElement(selectedValueUrl);
    });
}

quickView.productDimensions = function() {
    $('body').on('click', '.view-dimension-image', function (e) {
        var dimImage = $(".dimension-image");
        dimImage.click();
    });
}

quickView.bundleCallout = function() {
    $('body').on('product:afterAttributeSelect', function (e, response) {
        var product = response.data.product;
        var hasSleepBundle = false;
        var $calloutContainer = $('.js-quickview-callout-sleepbundle');
        
        if (product.recommendationBundles.multi || product.recommendationBundles.single) {
            var hasSleepBundle = true;
        }

        if (hasSleepBundle) {
            $calloutContainer.removeClass('d-none');
        } else {
            $calloutContainer.addClass('d-none');
        }
    });
}

quickView.addEventListeners = function() {
    $('body')
        .on('click', '.btn-update-wishlist-product', eventClickUpdate)
        .on('product:afterAttributeSelect', eventUpdateFromQuickView);
};
quickView.removeEventListeners = function() {
    $('body')
    .off('click', '.btn-update-wishlist-product', eventClickUpdate)
    .off('product:afterAttributeSelect', eventUpdateFromQuickView); 
}

quickView.init = function() {
    quickView.addEventListeners();
};

function eventClickUpdate(e) {
    e.preventDefault();

    var $this = $(this);
    var $modal = $('#quickViewModal');

    var core = require('core/product/base');
    var params = {
        pid: $this.data().pid,
        pidUpdated: $this.data().pidUpdated,
        uuid: $('.update-wishlist-url').data('uuid'),
        listOwnerUuid: $('.wishlistItemCardsData').data() && $('.wishlistItemCardsData').data().uuid ? $('.wishlistItemCardsData').data().uuid: '',
        selectedOptionValueIds: core.methods.getOptions($modal)
    };
    params = $.param(params);
    $.ajax({
        url: $('.update-wishlist-url').val() + '?' + params,
        type: 'POST',
        dataType: 'html',
        success: function (html) {
            var $html = $(html);
            $('.product-list-enhancements-global-error-display').trigger('clear');
            $.spinner().stop();
            $('.product-list-enhancements-detail').trigger('updateProducts', $html.find('.product-list-enhancements-detail-products'));
            $modal.modal('hide');
        },
        error: function () {
            $('.product-list-enhancements-global-error-display').trigger('error');
            $modal.modal('hide');
            $.spinner().stop();
        }
    });
};


function eventUpdateFromQuickView(e, payload) {
    var $updateButton = payload.container.closest('.modal').find('.btn-update-wishlist-product');
    if (payload.data.product.masterId) {
        $updateButton
            .data('pid-updated', payload.data.product.id)
            .attr('data-pid-updated', payload.data.product.id)
            .removeAttr('disabled');
    } else {
        $updateButton
            .data('pid-updated', payload.data.product.id)
            .attr('data-pid-updated', payload.data.product.id)
    }
}

module.exports = quickView;
