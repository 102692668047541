'use strict';

var storeLocator = require('client_core/storeLocator/storeLocator');
var base = require('client_core/product/base');
var slider = require('core/components/slider');

/**
 * Restores Quantity Selector to its original state.
 * @param {HTMLElement} $quantitySelect - The Quantity Select Element
 */
function restoreQuantityOptions($quantitySelect) {
    $quantitySelect.each((i, el) => {
        var originalHTML = $(el).data('originalHTML');
        if (originalHTML) {
            $(el).html(originalHTML);
        }
    });
}

/**
 * Sets the data attribute of Quantity Selector to save its original state.
 * @param {HTMLElement} $quantitySelect - The Quantity Select Element
 */
function setOriginalQuantitySelect($quantitySelect) {
    if ($quantitySelect && $quantitySelect.length > 0) {
        $quantitySelect.each((i, el) =>
            $(el).data('originalHTML', $(el).html())
        );
    }
}

/**
 * Updates the Quantity Selector based on the In Store Quantity.
 * @param {string} $quantitySelector - Quantity Selector
 * @param {number} productAtsValue - Inventory in the selected store
 */
function updateQOptions($quantitySelector, productAtsValue) {
    var selectedQuantityValues = $quantitySelector.map((i, el) => $(el).val());
    restoreQuantityOptions($quantitySelector);

    $quantitySelector.find('option').each((index, option) => {
        if (index >= productAtsValue) {
            $(option).remove();
        }
    });

    $quantitySelector.each((i, el) =>
        $(el).find('option[value="' + selectedQuantityValues[i] + '"]').attr('selected', 'selected')
    );

    // update qty stepper if enabled
    var $qtyStepper = $quantitySelector.closest('.quantity').find('.quantity-stepper');
    if ($qtyStepper.length) {
        $qtyStepper.attr('data-max', productAtsValue);
        base.enableQuantitySteppers($qtyStepper.closest('.quantity'));
    }
}

function modalShiftTabADA(element) {
    var capture = element
        .attr('tabindex', '-1')
        .focus()
        .keydown(
            function handleKeydown(event) {
                if (event.key.toLowerCase() !== 'tab') {
                    return;
                }
                var tabbable = $()
                    .add(capture.find('button, input, select, textarea'))
                    .add(capture.find('[href]'))
                    .add(capture.find("[tabindex]:not([tabindex='-1'])"))
                ;
                var target = $(event.target);
                if (event.shiftKey) {
                    if (target.is(capture) || target.is(tabbable.first())) {
                        event.preventDefault();
                        tabbable.last().focus();
                    }
                } else {
                    if (target.is(tabbable.last())) {
                        event.preventDefault();
                        tabbable.first().focus();
                    }
                }
            }
        )
    ;
}

/**
 * Generates the modal window on the first call.
 */
function getModalHtmlElement($target) {
    var $target = $target;
    if ($('#inStoreInventoryModal').length !== 0) {
        $('#inStoreInventoryModal').remove();
    }
    var btnText = $('.btn-get-in-store-inventory').data('modal-close-text');
    var htmlString = '<!-- Modal -->'
        + '<div class="modal" id="inStoreInventoryModal" role="dialog" aria-hidden="true">'
        + '<div class="modal-dialog modal-lg in-store-inventory-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header justify-content-end">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal" '
        + '        aria-label="' +btnText+ '" title="' + btnText + '">'    // eslint-disable-line
        + '        <span aria-hidden="true">&times;</span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('header').append(htmlString);

    $('#inStoreInventoryModal').modal('show');
    modalShiftTabADA($('#inStoreInventoryModal'));

    $('body').on('hidden.bs.modal', '#inStoreInventoryModal', function () {
        $target.focus();
    });
}

/**
 * Replaces the content in the modal window with find stores components and
 * the result store list.
 * @param {string} pid - The product ID to search for
 * @param {number} quantity - Number of products to search inventory for
 * @param {number} selectedPostalCode - The postal code to search for inventory
 * @param {number} selectedRadius - The radius to search for inventory
 */
function fillModalElement(pid, quantity, selectedPostalCode, selectedRadius, saveLocation) {
    var requestData = {
        products: null
    };

    if (pid && quantity) {
        requestData.products = pid + ':' + quantity;
    }

    if (selectedRadius) {
        requestData.radius = selectedRadius;
    }

    if (selectedPostalCode) {
        requestData.postalCode = selectedPostalCode;
    }

    requestData.saveLocation = saveLocation;

    $('#inStoreInventoryModal').spinner().start();
    $.ajax({
        url: $('.btn-get-in-store-inventory').data('action-url'),
        data: requestData,
        method: 'GET',
        success: function (response) {
            $('#inStoreInventoryModal .modal-body').empty();
            $('#inStoreInventoryModal .modal-body').html(response.storesResultsHtml);
            storeLocator.search();
            storeLocator.changeRadius();
            storeLocator.selectStore(saveLocation);
            storeLocator.updateSelectStoreButton();
            storeLocator.detectLocation();

            $('.btn-storelocator-search').attr('data-search-pid', pid);

            if (selectedRadius) {
                $('#radius').val(selectedRadius);
            }

            if (selectedPostalCode) {
                $('#store-postal-code').val(selectedPostalCode);
            }

            var $sliderContainer = $('.stores-carousel .slider-container');
            if ($sliderContainer) {
                slider.initializeSliders($sliderContainer.parent());
            }

            $('#inStoreInventoryModal').modal('show');
            $('#inStoreInventoryModal').spinner().stop();
        },
        error: function () {
            $('#inStoreInventoryModal').spinner().stop();
        }
    });
}

/**
 * Remove the selected store.
 * @param {HTMLElement} $container - the target html element
 */
function deselectStore($container) {
    var storeElement = $($container).find('.selected-store-with-inventory');
    $(storeElement).find('.card-body').empty();
    $(storeElement).closest('.row').addClass('d-none');
    $($container).find('.btn-get-in-store-inventory').closest('.row').removeClass('d-none');
    $($container).find('.quantity-select').removeData('originalHTML');
}

/**
 * Update quantity options. Only display quantity options that are available for the store.
 * @param {sring} searchPID - The product ID of the selected product.
 * @param {number} storeId - The store ID selected for in store pickup.
 */
function updateQuantityOptions(searchPID, storeId) {
    var selectorPrefix = '.product-detail[data-pid="' + searchPID + '"]';
    var $productIdSelector = $(selectorPrefix).find('.product-id');
    var $quantitySelector = $(selectorPrefix).closest('.modal').length
        ? $(selectorPrefix).closest('.modal').find('.modal-footer .quantity-select')
        : $(selectorPrefix).find('.quantity-select');
    setOriginalQuantitySelect($quantitySelector);

    var requestData = {
        pid: $productIdSelector.text(),
        quantitySelected: $quantitySelector.val(),
        storeId: storeId
    };

    $.ajax({
        url: $('.btn-get-in-store-inventory').data('ats-action-url'),
        data: requestData,
        method: 'GET',
        success: function (response) {
            // Update Quantity dropdown, Remove quantity greater than inventory
            var productAtsValue = response.atsValue;
            var $productContainer = $('.product-detail[data-pid="' + searchPID + '"]');
            var productAvailable = (response.isDeliverable && response.isWithinDeliveryRange) || response.availableForInStorePickup;

            $productContainer.trigger('product:updateAvailability', {
                $productContainer: $productContainer,
                message: '<li><div>' + response.availabilityMessage + '</div></li>',
                product: {
                    available: productAvailable,
                    readyToOrder: productAvailable
                }
            });

            $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
                $productContainer: $productContainer,
                product: {
                    available: productAvailable,
                    readyToOrder: productAvailable
                }
            });

            updateQOptions($quantitySelector, productAtsValue);
        }
    });
}

function getQuantitySelect($target) {
    var $quantitySelect;

    if ($target.closest('#quickViewModal').length) {
        $quantitySelect = $target.closest('#quickViewModal').find('.modal-footer .quantity-select');
    } else {
        $quantitySelect = $target.closest('.product-detail').find('.quantity-select');
    }

    return $quantitySelect;
}

module.exports = {
    updateSelectedStoreOnAttributeChange: function () {
        $('body').on('product:afterAttributeSelect', function (e, response) {
            var storeId = $(response.container).find('.selected-store-with-inventory [data-store-id]').data('store-id');
            response.container.attr('data-pid', response.data.product.id);

            // update delivery block's expected delivery date message
            let deliveryAvailability = response.data.deliveryAvailability;
            if (deliveryAvailability.isDeliverable && deliveryAvailability.expectedDeliveryDate) {
                let expectedDeliveryDate = `${deliveryAvailability.expectedDeliveryDate.message} <span class="instock-date-js">${deliveryAvailability.expectedDeliveryDate.date}</span>`;
                $('.delivery-date').html(expectedDeliveryDate).removeClass('d-none');
            } else {
                $('.delivery-date').addClass('d-none');
            }

            if (storeId) {
                updateQuantityOptions(response.data.product.id, storeId)
            } else {
                deselectStore(response.container);

                // update qty stepper if enabled
                var $qtyStepper = $(response.container).closest('.modal').length
                                  ? (response.container).closest('.modal').find('.modal-footer .quantity-stepper')
                                  : (response.container).find('.quantity-stepper');
                if ($qtyStepper.length) {
                    var qtyMax = $qtyStepper.closest('.quantity').find('.quantity-select option').length;
                    $qtyStepper.attr('data-max', qtyMax);
                    base.enableQuantitySteppers($qtyStepper.closest('.quantity'));
                }
            }
        });
    },
    updateAddToCartFormData: function () {
        $('body').on('updateAddToCartFormData', function (e, form) {
            if (form.pidsObj) {
                var pidsObj = JSON.parse(form.pidsObj);
                pidsObj.forEach(function (product) {
                    var storeElement = $('.product-detail[data-pid="' +
                        product.pid
                        + '"]').find('.store-name');
                    product.storeId = $(storeElement).length// eslint-disable-line no-param-reassign
                        ? $(storeElement).attr('data-store-id')
                        : null;
                });

                form.pidsObj = JSON.stringify(pidsObj);// eslint-disable-line no-param-reassign
            }

            var storeElement = $('.product-detail[data-pid="'
                + form.pid
                + '"]');

            var deliveryType = $('input[name=deliverytype]:checked').val();

            if ($(storeElement).length && deliveryType == 'curbside') {
                form.storeId = $(storeElement).find('.store-name').attr('data-store-id'); // eslint-disable-line
            } else {
                delete form.storeId;
            }
        });
    },
    showInStoreInventory: function () {
        $('body').on('click', '.btn-get-in-store-inventory', event => {
            var $target = $(event.target);
            var pid = $target.closest('.product-detail').attr('data-pid');
            var $quantitySelect = getQuantitySelect($target);
            var quantity = $quantitySelect.val();

            getModalHtmlElement($target);
            fillModalElement(pid, quantity, null, null, $target.data('save-location'));
            event.stopPropagation();
        });
    },
    removeStoreSelection: function () {
        $('body').on('click', '#remove-store-selection', event => {
            var $target = $(event.target);
            var $quantitySelect = getQuantitySelect($target);

            deselectStore($target.closest('.product-detail'));
            $(document).trigger('store:afterRemoveStoreSelection', $quantitySelect);
        });
    },
    selectStoreWithInventory: function () {
        $('body').on('store:selected product:afterAttributeSelect', function (e, data) {
            if ($('.btn-store-name').length > 0 && data.pickupLocation) {
                $('.btn-store-name')[0].innerHTML = data.pickupLocation;
            }

            if (data.storeLocation && data.locationSaved) {
                $('.store-locator-btn-header').each(function(i, el) {
                    el.innerHTML = data.storeLocation;
                });
            } else if (data.storeLocation) {
                $('.store-locator-btn-header:not(.my-account)').each(function(i, el) {
                    el.innerHTML = data.storeLocation;
                });
            }

            var searchPID = $('.product-wrapper').data('pid');
            var $storeElement = $('.product-detail[data-pid="' + searchPID + '"]');

            if (data.storeDetailsHtml) {
                $storeElement.find('.selected-store-with-inventory .card-body').empty();
                $storeElement.find('.selected-store-with-inventory .card-body').append(data.storeDetailsHtml);
            }

            if (data.storeID) {
                $storeElement.find('.store-name').attr('data-store-id', data.storeID);
                $storeElement.find('.store-details').find('.store-details').attr('data-store-id', data.storeID);
            }

            if (data.searchPostalCode || data.searchRadius) {
                var $changeStoreButton = $storeElement.find('.change-store');
                $changeStoreButton.data('postal', data.searchPostalCode);
                $changeStoreButton.data('radius', data.searchRadius);
            }

            $storeElement.find('.selected-store-with-inventory').closest('.row').removeClass('d-none');

            var selectorPrefix = '.product-detail[data-pid="' + searchPID + '"]';
            var $quantitySelector = $(selectorPrefix).closest('.modal').length
                                    ? $(selectorPrefix).closest('.modal').find('.modal-footer .quantity-select')
                                    : $(selectorPrefix).find('.quantity-select');
            // update pdp based on storis delivery information. only update if the product is deliverable and the customer is within the set delivery range
            if (searchPID) {
                var url = $('.delivery').data('action');
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    data: {
                        pid: searchPID,
                        quantity: $quantitySelector.val(),
                        saveLocation: data.saveLocation,
                        isDropship: 'product' in data && data.product.DROP_SHIP,
                        isSpecialOrder: 'product' in data && data.product.SPECIAL_ORDER
                    },
                    success: function(data) {
                        if (!data.isMarketplaceProduct) {
                            if (data.isDeliverable) {
                                // update delivery block's expected delivery date message
                                if (data.expectedDeliveryDate) {
                                    let expectedDeliveryDate = `${data.expectedDeliveryDate.message} <span class="instock-date-js">${data.expectedDeliveryDate.date}</span>`;
                                    $('.home-delivery-option .delivery-date').html(expectedDeliveryDate);
                                }

                                var deliveryDate = data.inStockDate;
                                if (deliveryDate) {
                                    $('.instock-date-js').text(deliveryDate);
                                }

                                $('.delivery-date').removeClass('d-none');
                                $('.explore-options').removeClass('d-none');
                                $('input[id="home"]').prop('disabled', false);
                                $('input[id="home"]').prop('checked', true);
                                $('.delivery-availability').addClass('d-none');
                                $('.update-zip-code').addClass('d-none');
                            } else {
                                $('.delivery-date').addClass('d-none');
                                $('.explore-options').addClass('d-none');
                                $('input[id="home"]').prop('disabled', true);
                                $('input[id="curbside"]').prop('checked', true);
                                $('.delivery-availability').removeClass('d-none');
                                $('.update-zip-code').removeClass('d-none');
                            }

                            if (data.availableForInStorePickup) {
                                $('input[id="curbside"]').prop('disabled', false)
                            }

                            if (data.availableForInStorePickup || data.isDeliverable) {
                                $('.add-to-cart').removeAttr('disabled');
                                $('.delivery-block-js').removeClass('d-none');
                            } else {
                                $('.add-to-cart').attr('disabled', true);
                                $('.delivery-block-js').addClass('d-none');
                            }
                        }

                        if(!data.isMarketplaceProduct){
                            var $badgeProductContainer = $('.badge-product-container');
                            $badgeProductContainer.empty();
                            if (data.badges && data.badges.length > 0) {
                                $badgeProductContainer.html("<span class='badge-product "+data.badges[0].class+"'>" + data.badges[0].name + "</span>");
                            }
                        }

                        var $productContainer = $('.product-detail[data-pid="' + searchPID + '"]');
                        var productAvailable = (data.isDeliverable && data.isWithinDeliveryRange) || data.availableForInStorePickup;
                        $productContainer.trigger('product:updateAvailability', {
                            $productContainer: $productContainer,
                            message: '<li><div>' + data.availabilityMessage + '</div></li>',
                            product: {
                                available: productAvailable,
                                readyToOrder: productAvailable
                            }
                        });
                    }
                });
            }

            if (!data.keepModalOpen) {
                $('#inStoreInventoryModal').modal('hide');
                $('#inStoreInventoryModal').remove();
            }
        });
    },
    changeStore: function () {
        $('body').on('click', '.change-store', event => {
            var $target = $(event.target);
            var pid = $target.closest('.product-detail').attr('data-pid');
            var $quantitySelect = getQuantitySelect($target);
            var quantity = $quantitySelect.val();

            getModalHtmlElement($target);
            fillModalElement(pid, quantity, $target.data('postal'), $target.data('radius'), $target.data('save-location'));
        });
    }
};
