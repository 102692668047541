'use strict';

var cart = require('./cart/cart');
var processInclude = require('base/util');

var siteIntegrations = require('core/integrations/siteIntegrationsUtils');
var toggleObject = siteIntegrations.getIntegrationSettings();

var baseFiles = {
    cart: function () {
        return cart.init.call(cart);
    }
};

if (toggleObject.bopisCartridgeEnabled) {
    baseFiles.instore = require('integrations/integrations/bopis/cart/instore');
}

//Standalone paypal does not have a toggle
baseFiles.paypalUtils = require('integrations/integrations/paypal');

module.exports = {
    baseFiles: baseFiles,
    loadFunctions: function () {
        Object.keys(baseFiles).forEach(function (key) {
            processInclude(baseFiles[key]);
        });
    }
};
