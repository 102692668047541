'use strict';

module.exports = function() {
	$('body').on('click', '.grid-layout-toggle button', function() {
		var $productGrid = $('.product-grid');
		var $iconBar = $('.grid-layout-toggle');
		var viewSetting = $(this).val();
		var layoutTarget = $(this).attr('data-target');
		if (layoutTarget=='mobile') {
			$productGrid.attr('data-grid-mobile-view', viewSetting);
			$iconBar.attr('data-selected-mobile', viewSetting);
			const newUrl = new URL(window.location);
			newUrl.searchParams.set('gridLayout', viewSetting);
			history.replaceState(undefined, '', newUrl);
		} else {
			$productGrid.attr('data-grid-view', viewSetting);
			$iconBar.attr('data-selected-desktop', viewSetting);
			const newUrl = new URL(window.location);
			newUrl.searchParams.set('gridLayout', viewSetting);
			history.replaceState(undefined, '', newUrl);
        }
        $productGrid.trigger('plp:layoutUpdated');
	});

	$('body').on('click', '.js-morefilters', function() {
		var $filterDrawer = $('.vertical-filter-drawer');
		if ($filterDrawer.hasClass('active')) {
            $filterDrawer.removeClass('active');
            $filterDrawer.removeClass('d-flex');
        } else {
            $filterDrawer.addClass('d-flex');
			$filterDrawer.addClass('active');
            $('#price-range').collapse('show');
			$filterDrawer.focus();
		}
	});

	// Keybord accessibility for 'More Filters' drawer
	$('.vertical-filter-drawer').keydown(function (e) {
		// Listen for escape key to close drawer
		if (e.which === 27) {
			$('.js-closefilters').trigger('click');
		}
		// Listen for enter or space on card headers to open/close them
		if ($(e.target).hasClass('card-title')) {
			if (e.which === 13 || e.which === 32) {
				e.preventDefault();
				$(e.target).trigger('click');
			}
		}
		// Listen on drawer close button
		if ($(e.target).hasClass('btn-drawer-close')) {
			if (e.which === 13 || e.which === 32) { // Close drawer on space or enter
				e.preventDefault();
				$(e.target).trigger('click');
			} else if (e.which === 9 && e.shiftKey) { // Wrap focus on drawer
				e.preventDefault();
				$('.drawer-footer .text-link.reset').focus();
			} else if (e.which === 9) {
				e.preventDefault();
				$('.vertical-filter-drawer').focus();
			}
		}
		// Wrap focus on drawer
		if ($(e.target).hasClass('vertical-filter-drawer')) {
			if (e.which === 9 && e.shiftKey) {
				e.preventDefault();
				$('.btn-drawer-close').focus();
			}
		}
	});

	$('body').on('click', '.js-closefilters, .overlay', function () {
		var $filterDrawer = $('.refinements.vertical-filter-drawer');
        $filterDrawer.removeClass('active');
        $filterDrawer.removeClass('d-flex');
	});
};
