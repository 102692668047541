"use strict";

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
 function displayMessage(success, msg) {
    var status = success ? 'alert-success' : 'alert-danger';

    if ($('.email-signup-message').length === 0) {
        $('body').append('<div class="email-signup-message"></div>');
    }

    if (!msg && $('#fallback-fail-msg')) {
        var msg = $('#fallback-fail-msg').val();
    }

    $('.email-signup-message').append('<div class="email-signup-alert text-center alert ' + status + '">' + msg + '</div>');

    setTimeout(function () {
        $('.email-signup-message').remove();
    }, 3000);
}

module.exports = {
    klaviyoPush: function() {
        $('body').on('login:register:success footer:signup:success klaviyoSubscribe:success klaviyoUpdateSubscription:success', function (e, data) {
            if (data._learnq) { // klaviyo integration — identifies user
                _learnq.push([ 'identify', data._learnq ]);
            }
        });
    },

    /**
     * Enables user to opt in/opt out of klaviyo email subscriptions in account dashboard
     */
    klaviyoUpdateSubscription: function() {
        $('body').on('change', '.account-page .klaviyo-form .klaviyo-subscription', function (e) {

            //
            // Submit the Klaviyo Subscribe Form
            //
            var form = $('[name="klaviyoSubscribe"]');
            var subscribe = form.find('#klaviyo-subscription-checkbox');
            var csrf_token = form.find('[name="csrf_token"]').val();
            var email = subscribe.val();
            var isChecked = subscribe.is(':checked');

            var data = {
                email: email,
                marketingOptIn: isChecked ? true : false,
                unsubscribe: isChecked ? false : true,
                csrf_token: csrf_token
            }
            var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');

            if (email != null) {
                $.ajax({
                    url: form.attr('action') + '?' + queryString,
                    type: 'post',
                    data: queryString,
                    contentType: "application/json",
                    dataType: "json",
                    success: function (data) {
                        // push the checkbox data
                        $('body').trigger('klaviyoUpdateSubscription:success', data);
                        displayMessage(data.success, data.msg);
                    }
                });
            }
        });
    },
    /**
     * Enables user to click out of stock swatches and opt in to be notified via klaviyo email when back in stock
     */
    nonClickableAttribute: function () {
        $('body').on('product:swatchClicked', function (e, attr, toggleObject){
            var attribute = $(attr);
            var validUrl = attribute.attr('data-url');
            var viewOutOfStockItems = toggleObject.viewOutOfStockItems;

            if (viewOutOfStockItems && validUrl && validUrl !== 'null' && validUrl !== undefined) {

                var $productContainer = attribute.closest('.set-item');
                if (!$productContainer.length) {
                    $productContainer = attribute.closest('.product-detail');
                }

                var $addToCartBtn = $productContainer.find('.add-to-cart'),
                    $notifyBISBtn = $productContainer.find('[name="backInStockForm"]');

                //remove old form if it exists
                $notifyBISBtn.length ? $notifyBISBtn.remove() : '';

                // only use an attribute that's out of stock
                if (attribute.find('span[class*="-value"]').hasClass('available')) {
                    $addToCartBtn.hasClass('d-none') ? $addToCartBtn.removeClass('d-none') : '';
                    return;
                }

                var actionUrl = $('#kl_back_in_stock_area').val();
                if (actionUrl) {
                    $.ajax({
                        url: actionUrl + '?' + validUrl,
                        type: 'post',
                        data: validUrl,
                        dataType: "json",
                        success: function (data) {
                            $addToCartBtn.addClass('d-none');
                            $addToCartBtn.parent().prepend(data.template);
                        },
                        error: function () {
                            $addToCartBtn.hasClass('d-none') ? $addToCartBtn.removeClass('d-none') : '';
                            return;
                        }
                    });
                }
            }
        });
    },
    notifyWhenBackInStock: function () {
        $('body').on('click', '.notify-back-in-stock', function (e) {
            e.preventDefault();
            e.stopPropagation();
            //
            // Submit the Klaviyo Subscribe Form
            //
            var form = $(this).closest('form');
            var action = form.attr('action');
            var csrf_token = form.find('[name="csrf_token"]').val();
            var email = form.find('[type="email"]').val();
            var pid = form.find('[name="pid"]').val();

            email.includes('+') ? email = email.replace(/\+/g,'%2B') : '';

            var data = {
                email: email,
                csrf_token: csrf_token,
                pid: pid
            }
            var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
            var jsonData = JSON.stringify(data);

            var btn = $(this);
            var xhr = form.data('xhr');

            form.data('xhr', $.ajax({
                url: action + '?' + queryString,
                type: 'post',
                data: jsonData,
                dataType: "json",
                beforeSend: function () {
                    // Drop duplicate requests
                    xhr && xhr.abort && xhr.abort();
                    btn.attr('disabled', true);
                },
                success: function (data) {
                    // push the data to learnq
                    $('body').trigger('klaviyoSubscribe:success', data);
                    displayMessage(data.success, data.msg);
                },
                error: function ($xhr, err, other) {
                    displayMessage(false, $xhr.responseJSON && $xhr.responseJSON.message);
                },
                complete: function () {
                    btn.removeAttr('disabled');
                    form[0].reset();
                }
            }));
        });
    }
}