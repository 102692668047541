'use strict';

var exports = $.extend({}, require('core/storeLocator/storeLocator'));
var slider = require('core/components/slider');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
     var newUrl = url;
     if (url) {
         newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
             return key + '=' + encodeURIComponent(params[key]);
         }).join('&');
     }
    return newUrl;
}

/**
 * Renders the results of the search and updates the map
 * @param {Object} data - Response from the server
 */
function updateStoresResults(data) {
    var $resultsDiv = $('.results');
    var $mapDiv = $('.map-canvas');
    var hasResults = data.stores.length > 0;

    if (!hasResults) {
        $('.store-locator-no-results').show();
        $('.filter-results').hide();
        $resultsDiv.attr('data-has-results', false);
    } else {
        $('.store-locator-no-results').hide();
        $('.filter-results').show();
        $resultsDiv.attr('data-has-results', true);
    }

    $resultsDiv.empty()
        .data('has-results', hasResults)
        .data('radius', data.radius)
        .data('search-key', data.searchKey)
        .data('store-type', data.storeType);

    $mapDiv.attr('data-locations', data.locations);

    if ($mapDiv.data('has-google-api')) {
        maps();
    } else {
        $('.store-locator-no-apiKey').show();
    }

    if (data.storesResultsHtml) {
        $resultsDiv.append(data.storesResultsHtml);
    }

    if (hasResults) {
        $.each(data.storeTypes, function(storeType) {
            var storeTypeValue = data.storeTypes[storeType].value ||data.storeTypes[storeType].value
            if ($('.results [data-store-type="' + storeTypeValue + '"]').length > 0) {
                $('.filter-results [data-filter="' + storeTypeValue + '"]').show();
            }
        });

        exports.selectStore();

        var $sliderContainer = $('.stores-carousel .slider-container');
        if ($sliderContainer) {
            slider.initializeSliders($sliderContainer.parent());
        }
    }

    if (data.deliveryAvailability && data.deliveryAvailability.isDeliverable && data.isWithinDeliveryRange) {
        $('.delivery-available').removeClass('d-none');
        $('.delivery-options-link').removeClass('d-none');
        $('.delivery-unavailable').addClass('d-none');
    } else {
        $('.delivery-available').addClass('d-none');
        $('.delivery-options-link').addClass('d-none');
        $('.delivery-unavailable').removeClass('d-none');
    }
}

function updateAvailabilityResults(data) {
    if (data.searchPID) {
        // update pdp based on storis delivery information. only update if the product is deliverable and the customer is within the set delivery range
        if (data.deliveryAvailability && data.deliveryAvailability.isDeliverable && data.isWithinDeliveryRange) {
            var deliveryDate = data.deliveryAvailability.earliestDeliveryDate || data.deliveryAvailability.inStockDate;
            $('.instock-date-js').text(deliveryDate);
            $('.delivery-date').removeClass('d-none');
            $('.explore-options').removeClass('d-none');
            $('input[id="home"]').prop('disabled', false);
            $('input[id="home"]').prop('checked', true);
            $('.delivery-availability').addClass('d-none');
            $('.update-zip-code').addClass('d-none');
        } else {
            $('.delivery-date').addClass('d-none');
            $('.explore-options').addClass('d-none');
            $('input[id="home"]').prop('disabled', true);
            $('input[id="curbside"]').prop('checked', true);
            $('.delivery-availability').removeClass('d-none');
            $('.update-zip-code').removeClass('d-none');
        }

        var $productContainer = $('.product-detail[data-pid="' + data.searchPID + '"]');
        var productAvailable = (data.deliveryAvailability.isDeliverable && data.deliveryAvailability.isWithinDeliveryRange) || data.deliveryAvailability.availableForInStorePickup;
        $productContainer.trigger('product:updateAvailability', {
            $productContainer: $productContainer,
            message: '<li><div>' + data.deliveryAvailability.availabilityMessage + '</div></li>',
            product: {
                available: productAvailable,
                readyToOrder: productAvailable
            }
        });

        $productContainer.trigger('product:updateAddToCart', {
            $productContainer: $productContainer,
            product: {
                available: productAvailable,
                readyToOrder: productAvailable
            }
        });
    }
}

/**
 * Search for stores with new zip code
 * @param {HTMLElement} element - the target html element
 * @returns {boolean} false to prevent default event
 */
function search(element) {
    var dialog = element.closest('.in-store-inventory-dialog');
    var spinner = dialog.length ? dialog.spinner() : $.spinner();
    spinner.start();
    var $form = element.closest('.store-locator');
    var radius = $('.results').data('radius');
    var url = $form.attr('action');
    var urlParams = {
        radius: radius
    };

    var payload = $form.is('form') ? $form.serialize() : {
        postalCode: $form.find('[name="postalCode"]').val()
    };

    var cleanPayload = decodeURI(payload).replace(/\s/g, '')
    url = appendToUrl(url, urlParams);
    $.ajax({
        url: url,
        type: $form.attr('method'),
        data: cleanPayload,
        dataType: 'json',
        success: function (data) {
            updateStoresResults(data);
            updateAvailabilityResults(data);

            $('.delivery .btn-postal-code').html(data.searchKey.postalCode);
            var navigationObj = {};
            navigationObj.event = 'navigation';
            navigationObj.nav_type = 'find store';
            navigationObj.link_text = $('.btn-storelocator-search')[0].innerText;
            navigationObj.link_url = url;
            $('body').trigger('gtm:pushData', navigationObj);
        },
        complete: function () {
            spinner.stop();
        }
    });
    return false;
}

function updatePDPAvailabilityOnSearch(e) {
    var storeID = $('.store-result .selected .store-details').data('store-id');
    var selectedStore;

    if ($('.store-result').length && storeID) {
        selectedStore = $('.store-result[data-store-id=' + storeID + ']')[0];
    }

    if (selectedStore) {
        var storeData = $(selectedStore).data('store-info');
        var data = {
            pickupLocation: $(selectedStore).data('pickup-location'),
            storeID: storeID,
            searchRadius: null,
            searchPostalCode: storeData.postalCode,
            storeDetailsHtml: $(selectedStore).siblings('label').find('.store-details').html(),
            storeName: storeData.city + ', ' + storeData.stateCode,
            storeLocation: storeData.city + ', ' + storeData.stateCode,
            event: e,
            keepModalOpen: true,
            saveLocation: false
        };
        var url = $('.store-result .selected .select-store').data('action');

        if (url) {
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                data: {
                    postalCode: storeData.postalCode,
                    storeID: storeID
                },
                success: function() {
                    $('body').trigger('store:selected', data);
                }
            });
        }
    }
}

exports.selectStore = function (saveLocation) {
    $('.store-locator-container .select-store').on('click', function (e) {
        e.preventDefault();

        if ($(this).parents('.store-result').length) {
            var selectedStore = $(this).parents('.store-result')[0];
        } else {
            var selectedStore = $('.store-result[data-store-id=' + $(this).data('selected-store') + ']')[0];
        }

        var storeID = $(selectedStore).find('.store-details').data('store-id');
        var storeData = $(selectedStore).data('store-info');
        var pickupLocation = $(selectedStore).data('pickup-location');
        var postalCode = storeData.postalCode;
        var data = {
            pickupLocation: pickupLocation,
            storeID: storeID,
            searchRadius: null,
            searchPostalCode: postalCode,
            storeDetailsHtml: $(selectedStore).siblings('label').find('.store-details').html(),
            storeName: storeData.city + ', ' + storeData.stateCode,
            storeLocation: storeData.city + ', ' + storeData.stateCode,
            event: e,
            saveLocation: saveLocation
        };

        $.ajax({
            url: $(this).data('action'),
            type: 'get',
            dataType: 'json',
            data: {
                postalCode: postalCode,
                storeID: storeID,
                saveLocation: saveLocation
            },
            success: function(response) {
                data.locationSaved = response.locationSaved;
                $('body').trigger('store:selected', data);
            }
        });
    });
}

exports.search = function () {
    $('.store-locator-container form.store-locator').submit(function (e) {
        e.preventDefault();
        search($(this));
        updatePDPAvailabilityOnSearch(e);
    });
    $('.store-locator-container .btn-storelocator-search[type="button"]').click(function (e) {
        e.preventDefault();
        search($(this));
        updatePDPAvailabilityOnSearch(e);
    });
}

exports.detectLocation = function () {
    // clicking on detect location.
    $('.detect-location').on('click', function (e) {
        var $spinner = $('#inStoreInventoryModal').spinner();
        $spinner.start();

        if (!navigator.geolocation) {
            $spinner.stop();
            return;
        }

        var detectedPostalCode = $(this).data('current-postal-code');
        $('#store-postal-code').val(detectedPostalCode);

        navigator.geolocation.getCurrentPosition(function (position) {
            var $detectLocationButton = $('.detect-location');
            var url = $detectLocationButton.data('action');
            var radius = $('.results').data('radius');
            var urlParams = {
                radius: radius,
                lat: position.coords.latitude,
                long: position.coords.longitude
            };

            url = appendToUrl(url, urlParams);
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    $spinner.stop();
                    updateStoresResults(data);
                    $('.detect-location-invalid').removeClass('d-block');
                    updateAvailabilityResults(data);
                    updatePDPAvailabilityOnSearch(e);

                    var postalCode = data.deliveryAvailability ? data.deliveryAvailability.postalCode : detectedPostalCode;
                    $('.delivery .btn-postal-code').html(postalCode);
                }
            }).fail(function(){
                $spinner.stop();
            });
        },
        function () {
            $spinner.stop();
            $('.detect-location-invalid').addClass('d-block');
        }, {
            timeout: 5000
        });
    });
}

module.exports = exports;
