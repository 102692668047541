'use strict';

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - The ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if ($('.add-to-cart-messages').length === 0) {
        $('body').append(
            '<div class="add-to-cart-messages"></div>'
        );
    }

    $('.add-to-cart-messages').append(
        '<div class="alert '
            + messageType
            + ' add-to-basket-alert text-center" role="alert">'
            + response.message
            + '</div>'
    );

    setTimeout(function () {
        $('.add-to-basket-alert').remove();
    }, 5000);
}

/**
 * Returns price value
 * @param {models.price.default.DefaultPrice} priceModel - The price model
 * @returns {number} The price value
 */
function getPrice(priceModel) {
    return !priceModel.sales ? priceModel.list.value : priceModel.sales.value;
}

/**
 * Returns list price value
 * @param {models.price.default.DefaultPrice} priceModel - The price model
 * @returns {number} The list price value
 */
function getListPrice(priceModel) {
    return priceModel.list ? priceModel.list.value : '';
}

/**
 * Add an offer to the cart
 */
function addOfferToCart() {
    $(document).on('click', 'button.offer-add-to-cart', function () {
        var addToCartObject = $(this).data('mirakl')
            ? $(this).data('mirakl')
            : null;
        if (addToCartObject) {
            $('.quick-view-dialog').spinner().start();

            var addToCartUrl = addToCartObject.url;
            var pid = addToCartObject.pid;
            var priceInfo = addToCartObject.priceInfo;
            var productName = addToCartObject.productName;
            var channels = addToCartObject.channels;
            var price = getPrice(addToCartObject.price);
            var listPrice = getListPrice(addToCartObject.price);
            var operatorProductId;
            var bundleProductsSelected = $('.js-product-tile-bundle-checkbox:checked, .js-product-tile-bundle-radio:checked');
            var isPDPdata = $(this).parents().hasClass('product-data');
            if ($('.modal.show .product-quickview').length) {
                operatorProductId = $('.modal.show')
                    .find('.product-quickview')
                    .data('pid');
            } else {
                operatorProductId = $('.product-id').text();
            }
            var shopName = addToCartObject.shopName;
            var offerState = addToCartObject.offerState;
            var offerStateCode = addToCartObject.offerStateCode;
            var shopId = addToCartObject.shopId;
            var quantity = $(this)
                .closest('.offer-details')
                .find('.offer-quantity-select')
                .val();
            var pidsObj, setPids;
            if (bundleProductsSelected.length > 0 && isPDPdata) {
                // Recommended bundle products
                setPids = [];
    
                // Add landing page product into pidsObj since Cart-AddProduct only adds a single pid or pidsObj
                setPids.push({
                    pid: pid,
                    price: price,
                    channels: channels,
                    listPrice: listPrice,
                    qty: quantity,
                    operatorProductId: operatorProductId,
                    shopName: shopName,
                    offerState: offerState,
                    offerStateCode: offerStateCode,
                    shopId: shopId,
                    priceInfo: priceInfo
                });
    
                bundleProductsSelected.each(function () {
                    var bundleProductTile = $(this).closest('.product-tile-bundle');
                    var ThisTileMiraklData = bundleProductTile.data('mirakl');
                    setPids.push({
                        pid: ThisTileMiraklData.pid,
                        price: getPrice(ThisTileMiraklData.price),
                        channels: ThisTileMiraklData.channels,
                        listPrice: getListPrice(ThisTileMiraklData.price),
                        qty: bundleProductTile.find('.quantity-stepper input').val(),
                        operatorProductId: bundleProductTile.attr('data-pid'),
                        shopName: ThisTileMiraklData.shopName,
                        offerState: ThisTileMiraklData.offerState,
                        offerStateCode: ThisTileMiraklData.offerStateCode,
                        shopId: ThisTileMiraklData.shopId,
                        priceInfo: ThisTileMiraklData.priceInfo
                    });
                });
                pidsObj = JSON.stringify(setPids);
            }

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                price: price,
                channels: channels,
                listPrice: listPrice,
                productName: productName,
                quantity: quantity,
                operatorProductId: operatorProductId,
                shopName: shopName,
                offerState: offerState,
                offerStateCode: offerStateCode,
                shopId: shopId,
                priceInfo: priceInfo
            };

            $(this).trigger('updateAddToCartFormData', form);

            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        handlePostCartAdd(data);
                        if(data.gtmData){
                            $('body').trigger('gtm:pushData', data.gtmData);
                        }
                        $('body').trigger('product:afterAddToCart', data);
                        $.spinner().stop();
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        }
    });
}

module.exports = {
    addOfferToCart: addOfferToCart
};
