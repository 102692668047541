'use strict';

function initHistorySelect() {
    $('body').on('change', '.order-history-select', function (e) {
        var $ordersContainer = $('.order-list-container');
        $ordersContainer.empty();
        $.spinner().start();
        $('.order-history-select').trigger('orderHistory:sort', e);
        $.ajax({
            url: e.currentTarget.value,
            method: 'GET',
            success: function (data) {
                $ordersContainer.html(data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                $.spinner().stop();
            }
        });
    });
};

function closeHistoryCallout() {
    $('body').on('click', '.order-history-callout .icon-close', function(e) {
        $('.order-history-callout').hide();
    });
}

function initOrderSearch() {
    $('body').on('input', '#order-search', function() {
        var input = this.value;
        $('.order-card').each(function(index, element) {
            if (element.dataset.order.startsWith(input.toUpperCase()) || input == '') {
                $(element).show();
            } else {
                $(element).hide();
            }
        });
    });
}

// Function to scroll to correct place when clicking pickup instruction or check delivery link
function pickupInstruction() {
    $('#pickup-section').on('load', function () {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.has('pickup-instruction')) {
            let yOffset = -180;
            let element = document.querySelector('#pickup-section');
            let y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        } else if (urlParams.has('check-delivery')) {
            let yOffset = -180;
            let element = document.querySelector('#delivery-timeline');
            let y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            $('#delivery-timeline').trigger('click');
        }
    });
    $(window).on('load', function () {
        $('#pickup-section').trigger('load');
    });
}

function loadMoreOrders(params) {
    $('.js-load-more-orders').on('click', (element) => {
        $.spinner().start();
        $.ajax({
            url: $(element.currentTarget).data('href'),
            method: 'GET'
        }).then((response) => {
            if (response && response.renderedTemplate) {
                $('.load-more-container').replaceWith(response.renderedTemplate);
            }
        }).always(() => {
            $.spinner().stop();
        });
    });
}

module.exports = {
    initHistorySelect: initHistorySelect,
    closeHistoryCallout: closeHistoryCallout,
    initOrderSearch: initOrderSearch,
    pickupInstruction: pickupInstruction,
    loadMoreOrders: loadMoreOrders
};
